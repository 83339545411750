.cl_members_grid,
.cl_fans_grid {
    //overflow: auto;

    div[class^='col-'] {
        overflow: hidden;

        .cl_img_container {
            width: 100px;
            height: 100px;
            margin: auto;
            position: relative;
            padding: 0;


            img {
                width: 100%;
                height: 100%;
                @include border-radius(50%);
                position: relative;
                z-index: 1;
            }


            .cl_percentage {
                z-index: 0;
                background-color: $color-glowing-members;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include border-radius(50%);
            }
            ///.cl_percentage ,
        }
    }

    .cl_items {
        //margin-top: 20px;
        text-align: center;
        padding: 20px 0 0 0;


        img {
            z-index: 1;
            cursor: pointer;
            // position: absolute;
            // top: 20px;
            //left: 20px;
            //  height: 100px;
            // width: 100px;


            @include transform(translateZ(0));
            @include transition(all 0.3s ease);

            &:hover {
                @include transform(scale(0.9));
            }


        }
        span {
            display: block;
            margin-top: 15px;
            font-size: 12px;
        }
        .cl_percentage {
            // @extend img;
            // z-index: 0;
            // background-color: #00d9da;
        }
    }
}


.cl_body {
    background-color: rgb(244, 248, 249);
}

.cl_members {
    width: 100%;
    padding: 0px 20px;

    .cl_members_grid {
        .cl_items {
            .cl_img_container {
                //height: 120px;

                &:hover {
                    .cl_img_hover {
                        visibility: visible;
                        @include transform(scale(1.05));
                        z-index: 99;
                        cursor: pointer;
                        text-shadow: 2px 2px 2px #575757;
                    }
                }

                .cl_img_hover {
                    text-shadow: 2px 2px 2px #575757;
                }
            }

        }
    }
}

.body_home {

    &.cl_boxCont {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        margin: 0 auto;
    }

    &.cl_fullWidth {
        width: 100%;
    }

    .main {

        .main_content {
            display: table;
            height: calc(100% - 70px);
            width: 100%;
            color: #fff;
            font-family: 'Montserrat', sans-serif;
            box-sizing: border-box;

            > div {
                @media (min-width: $cl-breakpoint-lg) {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            .the_songs_that,
            .sets_your_stage,
            .your_songs,
            .your_stage {
                display: block;
                font-size: 3.5rem;

                @media (min-width: $cl-breakpoint-xl) {
                    font-size: 84px;
                }

                @media (max-width: $cl-breakpoint-md - 1) {
                    font-size: 84px;
                }

                @media (max-width: $cl-breakpoint-sm - 1) {
                    font-size: 3rem;
                }

                @media (max-width: 319px) {
                    font-size: 3rem;
                }
            }

            .the_songs_that,
            .your_songs {
                font-weight: 700;
            }

            .sets_your_stage,
            .your_stage {
                font-weight: 200;
            }

            .scroll_main {
                display: inline-block;
                margin-top: 20px;
                padding: 25px 13px 13px 13px;
                border: 1px solid #fff;
                @include border-radius(20px);

            }

            .sing_snap_it {
                margin: 40px 0;
                font-size: 20px;
                font-weight: 500;
                display: block;
                .bold_everyone {

                    font-weight: 700;
                }

                @media (max-width: $cl-breakpoint-md - 1) {
                    font-size: 1.5rem;

                    br {
                        display: none;
                    }
                }
            }

            .cl_start_now {
                font-family: 'Montserrat', sans-serif;
                background-color: #8dd9d9;
                color: #fff;
                height: 45px;
                padding: 0 40px;
                font-size: 12px;
                font-weight: bold;
                cursor: pointer;
                @include border-radius(19rem);
                @include box-shadow(10px 10px 40px 0 rgba(0, 0, 0, 0.3));
                @include transition(all 0.09s ease-in);

                &:hover {
                    @include transform(scale(1.05));
                }

                &:active {
                    @include transform(scale(0.97));
                }

            }
        }
    }
}

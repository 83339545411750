.security_box {

    .pass_tab {
        padding: 0 10% 0 0;
    }

    .email_tab {
        padding: 0 5%;

    }
    .cl_error {
        font-size: .7rem;
        color: #FF00B1;
    }
}

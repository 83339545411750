/*
*   Grid system vars:
*
*   Breakpoints:
*   $xl (default: 1200px) -  min width for extra large screens
*   $lg (default: 992px) -  min width for large screens
*   $md (default: 768px) -  min width for medium screens
*   $sm (default: 576px) -  min width for small screens
*
*   $columnRange (default: 12) - max number of columns
*
*   $clsSufix (default: null) - sufix added to column classes (example: col-lg-12-sufix (where sufix is : -sufix)
*
*   $paddingLeft (default: 10px) - all generated classes left padding , to turn off left padding use value : null
*   $paddingRight (default: 10px) - all generated classes right padding , to turn off right padding use value : null
*
*   To customaze all generated clases using @content see example below:
*
*
*   @include gridResponsiveByNumber(){
*       //custom style will be added to all generated classes
*       color: red;
*    }
*
*
*/

@mixin gridResponsiveByNumber($xl: 1200px, $lg: 992px, $md: 768px, $sm: 576px, $columnRange: 12, $clsSufix: null, $paddingLeft: 10px, $paddingRight: 10px) {

    $breakpointList: (xl, $xl), (lg, $lg), (md, $md), (sm, $sm);
    $breakpointLenght: length( $breakpointList);
    $colRange: $columnRange;

    $col-list: '';
    $tmp: '';
    @for $i from 1 through $colRange {
        @for $y from 1 through $breakpointLenght {

            $tmp: col-#{nth(nth($breakpointList,$y),1)}-#{$i}$clsSufix;

            @media (min-width: nth(nth($breakpointList,$y),2)) {
                .#{$tmp} {
                    max-width: calc(100% / $i);
                    -webkit-box-flex: 0;
                    -webkit-flex: 0 0 calc(100% / $i);
                    -ms-flex: 0 0 calc(100% / $i);
                    flex: 0 0 calc(100% / $i);
                }
            }

            $col-list: $col-list + unquote(" .#{$tmp},");
        }
    }

    #{$col-list} {
        position: relative;
        width: 100%;
        //float: left;
        min-height: 1px;
        padding-right: $paddingLeft;
        padding-left: $paddingRight;

        @content;
    }
}

//@include gridResponsiveByNumber();

.ssb-navbar {
    display: table;
    height: 52.5pt;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    font-weight: bold;
    //padding: 0 20px;

    a {
        color: #fff;
        text-decoration: none;
    }

    .singsnap_logo {
        display: table-cell;
        vertical-align: middle;
        font-size: 13.5pt;
        padding-left: 20pt;

        @media (max-width: 319px) {        
            display: block;
        }

        .logo {
            margin-right: 3.75pt;

            &:hover {
                -webkit-stroke-width: 10px;
                -webkit-stroke-color: rgb(255, 255, 255);
                -webkit-fill-color: rgb(255, 255, 255);
                @include text-shadow(0 0 10px rgb(255, 255, 255));
            }
        }

        span {
            cursor: pointer;

            &:hover {
                -webkit-stroke-width: 10px;
                -webkit-stroke-color: rgb(255, 255, 255);
                -webkit-fill-color: rgb(255, 255, 255);
                @include text-shadow(0 0 10px rgb(255, 255, 255));
            }
        }
    }

    .ssb-actions {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        display: inline-block;
        line-height: 70px;
        margin-right: 30px;
        font-size: 13px;
        vertical-align: middle;

        @media (max-width: $cl-breakpoint-md - 1) {
            margin-right: 15px;
            display: inline-block !important;
        }

        @media (max-width: 319px) {    
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;    
            display: block;
        }

        &:hover {
            -webkit-stroke-width: 10px;
            -webkit-stroke-color: rgb(255, 255, 255);
            -webkit-fill-color: rgb(255, 255, 255);
            @include text-shadow(0 0 10px rgb(255, 255, 255));
        }
        //login btn
        &--login{
            font-weight: 700;
            padding-left: 20px;
            padding-right: 20px;
            letter-spacing: 1px;
            line-height: 40px;
            margin-top: 14px;
            margin-right: 30px;
            font-size: 12px;
            vertical-align: middle;
            text-align: center; 
            width: 125px;
            height: 43px;
            display: inline-block;
            background:#58c7c9;
            border-color: #58c7c9;
            border-radius: 6px;
            cursor: pointer;
        }
        &--login:hover {
           background-color: rgba(77, 166, 173, 0.80);
           border-color: rgba(77, 166, 173, 0.80);
         
        }
    }
}

.hidden {
    display: none !important;
}

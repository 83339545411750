/* import sass variables */
@import '../../../../vars.scss';
#public{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}
.main_width {
    //height: 100%;
    display: flex;
    flex-direction: column;
}
#footerReplacement {
    height: auto;
    flex-grow: 2;
    width: 100%;
    background-color: #21243c;
}
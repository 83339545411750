/*  Sass variables */

$imgUrl: 'assets/pictures/';

$page-bgcolor: rgb(29, 25, 50); //#1d1932
$body-bgcolor: #f3f8f9;
$body-bgcolor-light: #fafafa;

$color: #292b2c;
$color-light: #a2a0ab;

$neutral-color: #cccccc;

$color-secondary: rgb(120, 118, 132); //#787684
$color-glowing: rgb(85, 218, 218); //#55dada
$color-glowing-members: #00d9da;
$color-glowing-loading: #5fd4db;
$color-glowing-light: #00b5b6;

// gray colors
$color-gray-05: #fbfbfb;
$color-gray-15: #d0d0d0;
$color-gray-30: #90919d;
$color-gray-80: #292b2c;

$color-text-main: $color-gray-80;
$color-text-sub: $color-gray-30;

$main-blue: #2e3244;
$blue: #3f8efb;
$font-light: #90919d;
$light-blue: #67cacb;
$gold: #b6974b;
$yellow: #f9c546;
$white: #fff;
$light-white: #efeff1;
$grey: #fbfbfb;
$light-grey: #90919d;
$black: #262628;
$dark-blue: #21243c;
$green: #58c7c9;
$purple: #6d74fb;
$light-red: #f94678;
$border-gray: #373a4f;
// night colors
$night-main-blue: #21243c;
$night-main-blue-alt: #16192a;
$night-secondary-blue: #2b2b56;
$night-border: #343a40;
$night-hover-color: #10132b;

// standard colors
$black: #000000;
$black10: rgba(0, 0, 0, 0.1);
$black20: rgba(0, 0, 0, 0.2);
$black40: rgba(0, 0, 0, 0.4);
$black70: rgba(0, 0, 0, 0.7);
$black80: rgba(0, 0, 0, 0.8);
$black90: rgba(0, 0, 0, 0.9);

$greyLight: #fbfbfb;
$greyLight2: #90919d;
$greyLight3: #daf0f0;
$greyLight4: #d0d0d0;
$grey: #262628;
$grey70: rgba(38, 38, 40, 0.7);
$grey90: rgba(38, 38, 40, 0.9);

//website
$website-footer-bgcolor: #23272a;

$font-size-nav: 14px;
$font-size-navSmall: 13px;
$font-size-navSmall-more: 10px;

//
$font-size-xs: 9px;

$containerWidth: 1380px;
$vievWidth: 1120px;
$sidebarWidth: 230px;
$sidebarHeight: 634px;

$main-Width: 1280px;

$modalDialogContentWidth: 1050px;
$modalDialogContentHeight: 400px;

$modalDialogContent2Width: 338px;
$modalDialogContentHeight: 512px;

//break points
$cl-breakpoint-xl: 1200px;
$cl-breakpoint-lg: 992px;
$cl-breakpoint-md: 768px;
$cl-breakpoint-sm: 576px;

//header
$headerBreakingPoint: 1300px;
$headerBreakingPoint-topRange: 1348px;

//fonts
$roboto: 'Roboto', sans-serif;
$poppins: 'Poppins', sans-serif;

//import mixins
@import 'sass/mixins';

/* icons */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

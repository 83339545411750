.account_box {
    .info_tab {
        padding-right: 10%;
        padding-left: 0;
    }

    .bio_box {
        padding-left: 5%;
        padding-right: 5%;
    }

    #gender{
        cursor: pointer;
    }
    .cl_error {
        font-size: .7rem;
        color: #FF00B1;
    }
    .st-error {
        position: relative;
    }
    .st-error_msg {
        position: absolute;
        bottom: -21px;
    }
}

.profile_header_body {
    height: 360px;
    background-color: rgb(244, 248, 249);//#F4F8F9
    @include linear-gradient(45deg, #f0aaff 10%, #4f00ff);
    margin-bottom: 45px;

    a {
        color: inherit;
        text-decoration: none;
    }

    .profile_header_left_container,
    .profile_header_details,
    .profile_header_left_container .profile_header_name,
    .profile_header_left_container .profile_header_description {
        font-family: 'Montserrat', sans-serif;
    }

    .profile_header_left_container {
        display: table;
        height: 360px;
        padding: 0 0 0 50px;
        color: #fff;
        box-sizing: border-box;

        .profile_header_name,
        .profile_header_description {
            font-weight: 400;
        }

        .profile_header_name {
            font-size: 24px;
        }

        .profile_header_description {
            font-size: 12px;
        }

        .cl_items {
            margin-bottom: 20px;

            .cl_img_container {
                height: 120px;
                position: relative;
                padding: 1px;
            }

            img {
                z-index: 1;
                position: absolute;
                left: 0;
                height: 120px;
                width: 120px;
                @include border-radius(50%);
                @include transform(translateZ(0));
                @include transition(all 0.3s ease);

                &:hover {
                    @include transform(scale(0.9));
                }
            }

            .cl_percentage {
                @extend img;
                z-index: 0;
                background-color: #00d9da;
            }
        }
    }

    .profile_header_details {
        height: 100%;
        color: white;
        font-weight: 400;
        padding: 50px 60px 50px 30px;

        .profile_header_details_item {
            font-size: 12px;
            margin-bottom: 5px;

            span {
                font-size: 13px;
            }
        }


        .profile_header_details_like,
        .profile_header_details_edit {
            margin-top: 50px;
            cursor: pointer;
            font-size: 18px;
            height: 30px;
            width: 30px;
            text-align: center;
            border: 1px solid #fff;
            @include border-radius(50%);
            @include transition(all 0.02s ease-in);

            &:hover {
                -webkit-stroke-width: 10.3px;
                -webkit-stroke-color: #f3f8f9;
                -webkit-fill-color: #f3f8f9;
                @include text-shadow(0 0 10px #95939f);
                @include transform(translateX(0%) scale(1.1));
            }

            &:active {
                color: #fff;
                @include transform(translateX(0%) scale(0.95));
            }
        }
    }
}

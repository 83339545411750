
@import 'vars';

@keyframes ripple {
  0%, 35% {
    transform: scale(1);
    opacity: 0.5;
  }
  80% {
    opacity: 0.2;
    transform: scale(1.4);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.circle-ripple {
  width: 70px;
  height: 70px;
  background: $light-red;
  border-radius: 50%;
  position: relative;

  &.circle-green {
    background: $green;

    .ripple-effect {
      background: $green;
    }
  }

  .ripple-effect {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: $light-red;
    border-radius: 50%;
    // animation: ripple 4.5s ease-out infinite;

    // &:nth-child(2) {
    //   animation-delay: 1.5s;
    // }

    // &:nth-child(3) {
    //   animation-delay: 3s;
    // }
  }

  &.icon-inner {
    .icon-holder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

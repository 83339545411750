@import '../app/components/website/main/main.component.scss';

.session-page {
  display: flex;
  width: 100%;
  min-height: 100%;

  @media only screen and (max-width: 56.25em) {
    #footerReplacement{
      flex-grow: 0.5;
    }
    .site-info-slider {
      visibility: hidden;
    }
    .session-form {
      width: 100%;
      max-height: 90%;
    }
    .form-group {
      width: 25rem;
    }
  }

  .session-form {
    width: 28.125rem;
    flex-shrink: 0;
    padding: 6px 60px;

    h3 {
      font-family: $poppins;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin: 18px 0 39px;
    }

    .social-signups {
      margin-bottom: 40px;
    }

    .social-signup {
      @include flex;
      margin-bottom: 30px;
      cursor: pointer;

      i {
        font-size: 20px;
        margin-right: 20px;
      }

      span {
        font-weight: bold;
      }

      &.social-facebook {
        color: #3f51a2;
      }

      &.social-gplus {
        color: #ef4a38;

        i {
          margin-right: 5px;
        }
      }
    }

    .form-group {
      position: relative;
      margin-bottom: 20px;

      label {
        font-size: 12px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        color: #00000050;
        margin: 0;
      }

      input.form-control {
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 6px;
        background: transparent;
      }

      &-input {
        border-bottom: 1px solid #ced4da;
        height: 38px;

        &:after {
          content: none;
        }

        > span {
          line-height: 38px;
        }
      }

      .input-error {
        color: $light-red;
        font-size: 14px;
      }

      &-dropdown {
        position: absolute;
        top: 30px;
        right: 10px;
        font-size: 20px;
      }
    }

    .forgot-link {
      display: block;
      color: $color-glowing;
      font-family: $roboto;
      font-size: 12px;
      font-weight: bold;
      right: 10px;
      top: 35px;
    }

    .session-help-text {
      @include flex;

      > span {
        font-size: 14px;
        font-weight: bold;
        color: #00000080;
        margin-right: 5px;
      }

      > h6 {
        font-size: 14px;
        font-weight: bold;
        color: $color-glowing;
        margin: 0;
      }
    }

    .btn-primary {
      margin: 20px 0 30px;
      padding: 12px 40px;
      font-weight: bold;
    }
  }

  .site-info-slider {
    flex: 1 1 1px;
    min-height: 50rem;
    height: auto;
    background: #6D74FB;
    background-image: url('/assets/pictures/login-pattern.png');
    background-repeat: no-repeat;
    background-position: center 200px;
  }
}

.form-submit-success {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px 50px;

  .icon-success {
    width: 142px;
    height: 142px;
    // border: 12px solid rgba(88, 199, 201, 0.4);
    // box-shadow: 0 0 0 12px rgba(88, 199, 201, 0.1);
    border-radius: 50%;
    margin: 30px auto 60px;

    .icon-inner {
      width: 100%;
      height: 100%;
      // background: #58C7C9;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .icon-holder {
    position: relative;
    z-index: 1;
  }

  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.24px;
    margin: 0 0 6px;
  }

  h6 {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #90919D;
    margin: 0 0 40px;
  }

}

.cl_settingsCont {

    /*
    .nav {
        position: relative;
        display: block;
    }

    .nav-tabs {

        &::before,
        &::after {
            display: table;
            content: " ";
            clear: both;
        }

        > li {
            float: left;

        }
    }
    */


    h2 {
        font-size: 1.65rem;
        margin-bottom: 3.5rem;
        font-weight: 400;
    }

    form {
        .form-group {
            padding-left: 0;
            height: 60px;
        }
    }

    button {
        height: 30px;
        color: #fff;
        cursor: pointer;
        @include border-radius(19rem);
        font-size: 10px;
        letter-spacing: 1.4px;

    }
    .st-select-form{
        padding-left: 15pxf;
    }
    label {
        font-size: 0.65rem !important;
        font-weight: 600;
        margin-bottom: 0;
    }

    .account_box,
    .notify_box,
    .privacy_box,
    .security_box,
    .social_box {
        button {
            background-color: #5CD9DA;
            width: 110px;
        }
    }

    .account_box,
    .notify_box,
    .privacy_box,
    .security_box {
        button {
            margin-top: 29px;
            margin-right: 16px;
        }
    }

    .social_box {
        button {
            margin-top: 3px;
        }
    }

    .sub_box {
        button {
            margin: 20px 0 20px 0;
            background-color:#FF00B1;
            width: 140px;
            clear: both;
        }
    }
    select {
        padding-left: 15px !important;
    }
    input,
    select,
    textarea {
        &:focus {
            border: none;
            background: transparent;
        }
    }

    .privacy_box {
        select {
            &:focus {
                padding-bottom: 3px !important;
            }
        }
    }

    input,
    select,
    textarea {
        border: none;
        background: transparent;
        border-bottom: 1px solid #DDDFE0;
        @include border-radius(0);
        font-size: 0.86rem;
        line-height: inherit;
        padding-left: 0;
        padding-bottom: 4px;
    }

    .account_box{
        input, select, textarea {
            cursor: text;
        }
    }

    .notify_box,
    .privacy_box {
        select {
            cursor: pointer;
        }
    }

    .security_box {
        input {
            cursor: text;
        }
    }

    /*  BOTTOM BARS  */
    .bar  {
        position:relative;
        display:block;

        &::before,
        &::after {
            content:'';
            height: 1px;
            width: 0;
            bottom: 1px;
            position: absolute;
            background: #FF00B1;
            @include transition(0.2s ease all);
        }

        &::before {
            left:50%;
        }

        &::after {
            right:50%;
        }
    }

    input,
    textarea,
    select {
        &:focus{
            ~ .bar {
                &::before,
                &::after {
                    width:50%;
                }
            }
        }
    }

    /*  REPLACE SELECT ICON  */
    select.input-lg {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        /* no standardized syntax available, no ie-friendly solution available */
    }


    .account_box,
    .privacy_box .privacy_tab.pull-left,
    .notify_box {
        i.fa {
            position: absolute;
            //left: 70px;
            margin-top: -23px;
            pointer-events: none;
            font-size: .1em;
            color: #FC5886;
        }
    }

    &.cl_content_container {
        background-color: rgb(244, 248, 249);//#F4F8F9
        padding: 50px 35px 10px 35px;

        .row {
            margin-right: 0;
        }


        .active {
            background: transparent;
            color: black;
        }

        .cl_fake_active_tab {
            border-bottom: 1px solid #ff00b1;
            a {
                color: black !important;
            }
        }

        .cl_nav_container {
            padding-right: 0;
        }

        .cl_sort_container {
            border-bottom: 1px solid #d0d1d3;
            padding-left: 0;
            padding-top: 10px;
            text-align: right;
            font-size: 10px;

            &:nth-child(1) {
                padding-right: 10px;
                color: #d0d1d3;
                border-right: 1px solid;
            }

           &:nth-child(2) {
                padding: 0 10px;
           }

            i {
                color: #f900ad;
                cursor: pointer;
            }
        }
    }

    .social_box,
    .security_box,
    .privacy_box,
    .notify_box,
    .account_box {
        padding: 70px 0 0 0;
        margin: 0 auto;
    }

    .sub_box,
    .social_box,
    .history_tab,
    .account_box,
    .social_box,
    .security_box,
    .privacy_box,
    .notify_box,
    .account_box {
        min-height: 800px;
    }

    .history_tab {
        padding-top: 70px;
        font-size: .75em;
    }

    .sub_box {
        margin: 0 auto;
        font-size: .85rem;
        //font-weight: 100;
    }

    //import individual blocks/tabs
    @import 'settings/account';
    @import 'settings/history';
    @import 'settings/notification';
    @import 'settings/privacy';
    @import 'settings/security';
    @import 'settings/social';
    @import 'settings/subscription';
}

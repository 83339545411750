//global components used throout app
.ngx-datepicker-container .ngx-datepicker-input {
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid #DDDFE0 !important;
    display: block !important;
    padding: .5rem .75rem !important;
    line-height: 1.25 !important;
    color: #464a4c !important;
    cursor: pointer !important;
    font-size: 0.86rem;
}

.is-selected{
    background: #FF00B1 !important;
}

.is-today {
    background: #5ad7d9 !important;
}
.day-unit:hover {
    background: #FF00B1 !important;
}

.cl_song {
    font-size: 12px;
    cursor: pointer;

    &:hover {
        -webkit-stroke-width: 10.3px;
        -webkit-stroke-color: $body-bgcolor;
        -webkit-fill-color: $body-bgcolor;
        @include text-shadow(0px 0px 10px #95939f);
    }
}

.cl_featured_row {
    .cl_song {
        padding-top: 10px;
        @include transition(all 0.02s ease-in);
    }
}

.cl_main_table {
    .cl_song {
        margin-left: 20px;
        display: inline-block;
        white-space: nowrap;
    }
}

.cl_profile {
    background-color: rgb(244, 248, 249); //#F4F8F9
    //   min-height: 100%;
    &.cl_profile_heights {
        min-height: 100vmin;
    }
}

.cl_artist {
    font-size: 10px;
}

.cl_author {
    font-size: 11px;
}

.cl_date {
    font-size: 10px;
}

.cl_packaging_tab {
    .cl_members_grid {
        .cl_items {
            margin-top: 40px;
        }
    }
}

.cl_fans_grid {
    .cl_items {
        margin-top: 40px;
    }
}

//title
.cl_title {
    display: inline-block;
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 5px;
    @include box-shadow(0 1px #ff00b1);
    @include transition(all 0.07s ease-in);
}

.cl_table {
    font-size: 12px;
}

.cl_content_container {
    .cl_table {
        font-size: 20px;
    }
}

//microphone sing
.microphone_sing {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 9px;
}

.contest_song_details,
.carousel_details,
.cl_main_table {
    .microphone_sing {
        cursor: pointer;

        &:hover {
            -webkit-stroke-color: #f3f8f9;
            -webkit-fill-color: #f3f8f9;
            @include text-shadow(0px 0px 10px #95939f);
        }
    }
}

.contest_song_details,
.carousel_details {
    .microphone_sing {
        color: #f3f8f9;
        @include transition(all 0.02s ease-in);
        margin-left: 5px;

        &:hover {
            -webkit-stroke-width: 10.3px;
            @include transform(translateX(0%) scale(1.2));
        }
        &:active {
            color: #cee2e4;
            @include transform(translateX(0%) scale(0.95));
        }
    }
}

.cl_main_table {
    .microphone_sing {
        margin-left: 2px;

        &:hover {
            -webkit-stroke-width: 5.3px;
        }

        &:active {
            color: #a2a0ab;
            @include transform(translateX(0%) scale(0.98));
        }
    }
}

//see more btn
.showMore_button {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #ff00b1;
}

//divider
.cl_table {
    //margin: 0 15px;

    .cl_devider {
        font-family: 'Montserrat', sans-serif;
        padding-bottom: 5px;
        width: 100%;
        @include box-shadow(0 1px #d2d3d3);
        @include transition(all 0.07s ease-in);
    }
}

.cl_content_container {
    .cl_table {
        .cl_devider {
            border-bottom: 1px solid #d2d3d3;
        }
    }
}

//divider
.cl_table {
    //margin: 0 15px;

    .cl_devider {
        font-family: 'Montserrat', sans-serif;
        padding-bottom: 5px;
        width: 100%;
        @include box-shadow(0 1px #d2d3d3);
        @include transition(all 0.07s ease-in);
    }
}

.cl_content_container {
    .cl_table {
        .cl_devider {
            border-bottom: 1px solid #d2d3d3;
        }
    }
}

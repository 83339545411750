
@import 'vars';

.subscription {
    &-block {
        padding: 25px;
        background: white;
        display: flex;
        justify-content: space-between;
        line-height: 1;
        margin-bottom: 10px;
        font-family: $roboto;
    }

    &-block-sub {
        &:nth-child(2) {
            text-align: right;

            .subscription-item-state {
                justify-content: flex-end;
            }
        }
    }

    &-icon {
        @include flex;

        .state-icon {
            width: 18px;
            height: 18px;
            background: #b8e986;
            border-radius: 50%;
            display: inline-block;
            @include flex-center;
        }

        .state-text {
            font-size: 12px;
            margin-left: 10px;
        }
    }

    &-item {
        font-size: 13px;
        margin-bottom: 10px;
    }

    &-item-faint {
        font-size: 14px;
        color: $font-light;
        margin-bottom: 15px;
    }

    &-item-state {
        @include flex;
        margin-top: 15px;

        + .subscription-item {
            margin-top: 15px;
        }

        .state-color {
            width: 10px;
            height: 10px;
            display: inline-block;
            background-color: greenyellow;
            border-radius: 50%;
            margin-right: 5px;
        }

        .state-image {
            margin-right: 5px;

            img {
                height: 15px;
            }
        }

        .state-text {
            font-weight: bold;
            font-size: 14px;
        }
    }

    &-history {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

$custom-border-radius: true;

//border radius
//@if ($custom-border-radius == true ){
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

//box shadow
@mixin box-shadow($args...) {
    -webkit-box-shadow: $args;
    -moz-box-shadow: $args;
    box-shadow: $args;
}

//transition
@mixin transition($args...){
    -webkit-transition: $args;
    -moz-transition: $args;
    -o-transition: $args;
    -ms-transition: $args;
    transition: $args;
}

//opacity
@mixin opacity($x){
    -webkit-opacity: $x;
    -moz-opacity: $x;
    filter:alpha(opacity=$x);
    opacity: $x;
}

//user select
@mixin user-select($x){
    -webkit-user-select: $x;
    -moz-user-select: $x;
    -ms-user-select: $x;
    user-select: $x;
}

//transform
@mixin transform($x){
    -ms-transform: $x;
    -webkit-transform: $x;
    -o-transform: $x;
    -moz-transform: $x;
    transform: $x;
}

//transform origin
@mixin transform-origin($x){
    -ms-transform-origin: $x;
    -webkit-transform-origin: $x;
    -o-transform-origin: $x;
    -moz-transform-origin: $x;
    transform-origin: $x;
}

//transform style
@mixin transform-style($x){
    -ms-transform-style: $x;
    -webkit-transform-style: $x;
    -o-transform-style: $x;
    -moz-transform-style: $x;
    transform-style: $x;
}

// backface-visibility
@mixin backface-visibility($x){
    -webkit-backface-visibility: $x;
    -moz-backface-visibility: $x;
    -o-backface-visibility: $x;
    -ms-backface-visibility: $x;
    backface-visibility: $x;
}

// box-sizing
@mixin box-sizing($x){
    -webkit-box-sizing: $x;
    -moz-box-sizing: $x;
    box-sizing: $x;
}

// background-size
@mixin background-size($x){
    -webkit-background-size: $x;
    -moz-background-size: $x;
    -o-background-size: $x;
    background-size: $x;
}

//animation
@mixin animation($x){
    -webkit-animation: $x;
    -moz-animation: $x;
    -o-animation: $x;
    -ms-animation: $x;
    animation: $x;
}

//animation delay
@mixin animation-delay($var){
    -webkit-animation-delay: $var;
    animation-delay: $var;
}

@function legacy-direction($value) {
    @if is-direction($value) == false {
        @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
    }

    $conversion-map: (
        to top          : bottom,
        to top right    : bottom left,
        to right top    : left bottom,
        to right        : left,
        to bottom right : top left,
        to right bottom : left top,
        to bottom       : top,
        to bottom left  : top right,
        to left bottom  : right top,
        to left         : right,
        to left top     : right bottom,
        to top left     : bottom right
    );

    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }

    @return 90deg - $value;
}
//linear gradient
@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: -moz-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

@mixin text-shadow($args...){
    -webkit-text-shadow: $args;
    -moz-text-shadow: $args;
    text-shadow: $args;
}

//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}
//placeholders

@mixin placeholders {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

.main-window {
    html, body {
        position: fixed;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
        font-family: $poppins;
        background: #fbfbfb;
        -webkit-overflow-scrolling: touch;
    }

    * {
        box-sizing: border-box;
    }

    h2 {
        color: #262628;
        font-family: $poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
    }

    h4 {
        color: #262628;
        font-family: Roboto, sans-serif;
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        margin: 0;
    }

    h5 {
        color: #262628;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        margin: 0;
    }

    h6 {
        color: #262628;
        font-family: Roboto, sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
        margin: 0;
    }

    // Define Weights HERE

    .weight-light {
        font-weight: 300;
    }

    .weight-regular {
        font-weight: 400;
    }

    .weight-medium {
        font-weight: 500;
    }

    .weight-semibold {
        font-weight: 600;
    }

    .weight-bold {
        font-weight: 700;
    }

    // Icon
    .app-icon {
        font-size: 0;
        cursor: pointer;
    }

    //Page
    .page-header {
        @include flex-center();
        justify-content: space-between;
        margin-bottom: 30px;

        .page-title {
            h2 {
                font-family: Helvetica, sans-serif;
                font-size: 20px;
                color: #262628;
            }
        }
    }

    .search-box {
        position: relative;
        padding: 16px 20px 15px;
        border: 1px solid #efeff1;
        border-radius: 3px;
        font-size: 0;
        width: 100%;
        max-width: 360px;

        app-icon-search {
            position: absolute;
            right: 20px;
            top: 16px;
        }
    }

    input.form-control {
        font-size: 12px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        line-height: 14px;
        border: none;
        background: transparent;
        width: 100%;
        outline: none;
        box-shadow: none;
        padding: 0;
        height: auto;

        &::placeholder {
            color: #90919d;
        }
    }

    .message-box {
        .box-header {
            background: #21243C;
            border-radius: 6px 6px 0 0;
            @include flex-center;
            justify-content: space-between;
            padding: 13px 15px 13px 15px;

            .sort-by {
                @include flex-center;
                cursor: pointer;

                h6 {
                    color: white;
                }
            }
        }
    }

    .message-list {
        app-message-item {
            display: block;

            &:nth-child(odd) {
                background: white;
            }

            &:first-child {
                border-radius: 0 0 5px 5px;
            }
        }

        .message-item {
            padding: 15px 15px 20px;
            border-radius: 5px;
        }
    }

    .message-header {
        @include flex-center;
        justify-content: space-between;

        .msg-header--left {
            @include flex-center;
            justify-content: space-between;
        }

        .msg-header--right {
            @include flex-center;
            justify-content: space-between;
        }

        .sender--image {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 9px 0 15px;
            background-size: cover;
            background-position: center center;
        }

        .sender--id {
            color: #b6974b;
            margin-right: 5px;
        }

        .user--mark {
            font-size: 10px;
            font-family: Roboto, sans-serif;
            font-weight: 500;
            line-height: 11px;
            border: 1px solid;
            border-radius: 3px;
            padding: 2px 5px;

            &.user--gold {
                border-color: #b6974b;
                color: #b6974b;
            }
        }

        .sender--points {
            font-size: 13px;
            font-family: Roboto, sans-serif;
            font-weight: 700;
            color: #58c7c9;
            margin-left: 5px;

            &:before {
                content: '';
                display: inline-block;
                width: 5px;
                height: 5px;
                background: #58c7c9;
                border-radius: 50%;
                margin-right: 3px;
                vertical-align: middle;
            }
        }

        .msg--time {
            font-size: 11px;
            color: #D0D0D0;
            margin: 0 15px 0 5px;
            line-height: 13px;
            font-family: Roboto, sans-serif;
        }
    }

    .message-content {
        padding-left: 109px;
        margin-top: 22px;
        position: relative;
        cursor: pointer;

        app-icon-star {
            position: absolute;
            left: 85px;
        }

        h4 {
            position: relative;
            height: 21px;

            span {
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                position: absolute;
                left: 0;
                right: 0;
            }
        }

        &.message--unread {
            h4 {
                color: #3f8efb;
            }

            &:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #3f8efb;
                left: 58px;
                top: 5px;
            }
        }
    }

    .message-board-content {
        .msg-item-main {
            .msg-item-content {
                p, h1, h2, h3, h4, h5, h6 {
                    margin: 0;
                }

                .emoji {
                    margin-bottom: 0;
                }

                img {
                    max-width: 100%;
                }

                iframe {
                    max-width: 100%;
                }
            }
        }
    }

    .ss-pagination {
        @include flex-center;
        margin-top: 30px;

        .icon {
            background: white;

            > * {
                height: 18px;
            }

            &.prev-icon {
                transform: rotate(90deg);
            }

            &.next-icon {
                transform: rotate(-90deg);
            }
        }

        .page-item {
            @include flex-center;
            border-radius: 6px;
            width: 30px;
            height: 30px;
            font-size: 13px;
            line-height: 15px;
            font-family: Roboto, sans-serif;
            font-weight: 700;
            cursor: pointer;

            &.active {
                background: #21243C;
                color: white;
            }

            + .page-item {
                margin-left: 15px;
            }
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        right: 0;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        padding: 10px 0;
        z-index: 111;
        background: white;
        cursor: pointer;
        display: block;

        .dropdown-item {
            font-size: 14px;
            padding: 5px 20px;
            cursor: pointer;

            &:hover {
                background: #f5f5f5;
            }
        }
    }

    .report-text {
        .custom-quill {
            quill-editor {
                .ql-editor {
                    font-size: 13px;
                    min-height: 110px !important;

                    &.ql-blank::before {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .report-msg {
        .user-msg {
            p {
                margin: 0;
            }
        }
    }

    input[type="file"] {
        display: none;
    }

    .custom-quill {
        .emoji {
            margin-bottom: 0;
        }

        quill-editor {
            display: flex;
            flex-direction: column-reverse;

            img {
                max-width: 250px !important;
                max-height: 250px !important;
            }

            #emoji-palette {
                #tab-panel {
                    justify-content: flex-start;

                    @media #{$sm} {
                        max-height: 122px;
                    }
                }
            }

            .ql-toolbar {
                border: none;
                padding: 0;

                .ql-formats {
                    button {
                        opacity: 0.4;

                        &.ql-active {
                            opacity: 1;
                        }
                    }
                }
            }

            .ql-container {
                border: none;
            }

            .ql-editor {
                min-height: 300px;
                padding: 0 0 20px 52px;
                font-size: 15px;

                &.ql-blank::before {
                    font-size: 15px;
                    line-height: 22px;
                    opacity: 0.4;
                    font-family: Roboto, sans-serif;
                    font-weight: 400;
                    color: #262628;
                    right: 0;
                    left: 52px;
                    font-style: normal;
                }
            }
        }

        &.quill-sm {
            quill-editor {
                .ql-editor {
                    min-height: 60px;
                    max-height: 200px;
                }

                @media #{$sm} {
                    .ql-editor {
                        max-height: calc(100vh - 254px);
                        min-height: 50px;
                        padding-bottom: 0;
                    }

                    .ql-toolbar {
                        display: none;
                    }
                }
            }
        }
    }

    .reply-quill-box {
        position: relative;

        .user-image {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            top: 14px;
            left: 10px;

            img {
                width: 100%;
            }
        }
    }

    .cta-buttons {
        display: flex;
        margin-top: 30px;
        margin-left: 10px;

        h6 {
            cursor: pointer;

            &:hover {
                color: #58c7c9;
            }
        }

        .btn-send {
            margin-right: 30px;

            &.btn-disabled {
                opacity: 0.4;
            }
        }
    }

    .message-tags {
        position: relative;

    }

    .btn.btn-custom {
        padding: 12px;
        width: 117px;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 14px;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
        box-shadow: none;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
        }

        &.btn-cancel {
            color: #90919D;
            background: transparent;
            border: 1px solid rgba(38, 38, 40, 0.1);
            margin-right: 27px;
        }

        &.btn-primary {
            color: white;
            background: #58C7C9;
            border-color: #58C7C9;
            border: none;
        }

        &.btn-secondary {
            border: none;
        }
    }

}
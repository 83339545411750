@import 'vars';

.featured-image {
}

.featured-image h1 {
    margin-top: 10px;
    margin-right: 10px;
    font-size: 0.8em;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    text-align: left;
}

.featured-image img {
    pointer-events: none;
    max-width: 170px;
    max-height: 170px;
}

.featured-image h1 .subtitle {
    font-size: 0.9em;
    color: $light-grey;
    margin-top: 5px;
    display: inline-block;
    font-weight: 300;
}

.featured-image--poster {
    height: 170px;
    width: 170px;
    background-size: cover;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    border-radius: 3px;
    span.featured-image--stats {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 30px;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 10px;
        background: rgba(54, 50, 49, 0.9);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        svg {
            width: 16px;
            height: 16px;
        }
        use {
            fill: #fff;
        }
        span{
            &:nth-child(1) svg{
                width: 12px;
                height: 12px;
            }
            &:nth-child(2) svg{
                width: 16px;
                height: 12px;
            }
            &:nth-child(3) svg{
                width: 12px;
                height: 12px;
            }
        }
    }
}

.featured-image--poster a {
    height: 100%;
    display: inline-block;
}

.featured-image--poster img {
    visibility: hidden;
    opacity: 0;
}
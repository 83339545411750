.floating-box {
    @include box-shadow(0 1px #d2d3d3);
    float: left;
    padding: 15px 0 17px 0;

    &.contests {
        @include box-shadow(none);
        padding-left: 20px;
        height: 145px;
        .member_details {
            font-family: 'Montserrat', sans-serif;
            @include transition(all 0.02s ease-in);
            margin-top: 10px;

            > span {
                display: block;

                span {
                    color: #ff00b1;
                }
            }

            .cl_recordings_date {
                font-size: 10px;
            }

            .cl_votes {
                font-size: 24px;
                color: #ff00b1;
                font-weight: 500;
            }

            .cl_votes_label {
                font-size: 10px;
                color: #ff00b1;
                margin-top: -5px;
            }

            .cl_member_name {
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    -webkit-stroke-width: 5.3px;
                    -webkit-stroke-color: #f3f8f9;
                    -webkit-fill-color: #f3f8f9;
                    @include text-shadow(0px 0px 10px #95939f);
                }

                &:active {
                    @include transform(translateX(0%) scale(0.98));
                    color: #a2a0ab;
                }
            }
        }

        .cl_wrap_img {

            .cl_member_img {
                @include border-radius(50%);
                height: 96px;
                width: 96px;
            }

            &:hover {
                .cl_member_img {
                    @include transform(scale(1.05));
                }
            }
        }

    }

    &:nth-child(5) {
        box-shadow: none;
    }
    &:nth-child(6) {
        box-shadow: none;
    }


    .cl_wrap_img {
        cursor: pointer;

        &:hover {
            .cl_recordings_img,
            .cl_duets_img {
                @include transform(scale(1.05));
            }

            .cl_img_hover {
                visibility: visible;
                @include transform(scale(1.05));
                text-shadow: 2px 2px 2px #575757;
            }

        }

        img.cl_img_hover {
            top: 34px;
            left: 50px;
        }

        .cl_img_hover {
            position: absolute;
            visibility: hidden;
        }
    }
}


.cl_recordings {
    &.cl_myfav-rec {
        .floating-box {
            .cl_wrap_img {
                .cl_img_hover {
                    top: 35px;
                    left: 52px;
                }
            }

            &:hover {
                @include transform(scale(1));
            }
        }
    }
}

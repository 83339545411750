.cl_topHeader_nav {
    height: 44px;
    background-color: $body-bgcolor;
    position: fixed;
    top: 0;
    margin-bottom: 0;
    max-width: calc(100% - #{$sidebarWidth});
    width: 100%;

    @media (max-width: $containerWidth) {
        //width: calc(100% - 245px);
    }

    @media (max-width: $cl-breakpoint-md - 1) {
        height: 90px;
    }

    *:focus {
        outline: none;
    }

    ::-moz-focus-inner {
        border: 0;
    }

    input {
        &:focus {
            outline: none;
        }
    }

    .cl_bell {
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;

        .cl_component {
            width: 44px;
            height: 44px;
        }

    }

    //bootstrap override
    &.navbar {
        padding: 0 1rem;
    }

    .cl_purchaseBtn,
    .cl_searchBtn {
        color: #fff;
        height: 30px;
        width: 110px;
        //margin-right: 16px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        @include border-radius(19rem);
    }

    .cl_topHeader {
        //padding: 2px 0 0;//2px 10px 0; //5px 10px;
        margin-top: 3px;
        margin-bottom: -2px;
        //height: 40px;
        background-color: $body-bgcolor;

        width: 100%;

        .cl_searchActionbar,
        .cl_searchCont,
        .cl_actionBar,
        .cl_bell {
            display: inline-table;
        }

        .cl_searchCont {
            width: 50%;
            height: 40px;

            @media (max-width: $cl-breakpoint-md - 1) {
                width: 100%;
            }
        }

        .cl_actionBar  {
            width: calc(50% - 44px);
            float: right;
            margin-right: 44px;

            @media (max-width: $cl-breakpoint-md - 1) {
                width: 100%;
                padding-top: 5px;
            }
        }

        .cl_bell {
            @media (max-width: $cl-breakpoint-md - 1) {
                top: 46px;
            }
        }

        .cl_searchActionbar {
            width: calc(50% - 6px);
            padding: 5px 5px 0 0;

            @media (max-width: $cl-breakpoint-md - 1) {
                width: 100%;
            }
        }
    }

    .cl_actionBar {
        text-align: right;

        @media (min-width: $headerBreakingPoint) {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 40%;
            -ms-flex: 0 0 40%;
            flex: 0 0 40%;
            max-width: 40%;
        }

        .cl_headerUserName,
        .cl_userAvatar,
        .badge_level {
            cursor: pointer;
            font-family: 'Montserrat';
        }

        .cl_headerUserName {
            text-align: right;
            font-weight: bold;
            font-size: 10pt;
            margin: 8px 20px 0;
            //float: left;
        }

        .cl_userAvatar {
            @include border-radius(50%);
            padding-bottom: 2px;
            height: 37px;
            width: 37px;
        }

        .badge_level {
            vertical-align: middle;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 6pt;
            height: 20px;
            width: 20px;
            @include border-radius(50%);
            background: #ff00b1;
            position: relative;
            left: -13px;
            border: 2px solid #f3f8f9;
            line-height: normal;

            //margin-right: 19px;
            padding: 3px;
        }

        .cl_purchaseBtn {
            background-color: #ff00b1;
            margin-top: 2px;
        }

        .dropdown-menu {
            min-height: 250px;
            width: 230px;
            background-color: #f3f8f9;
            top: 160%;
            //left: 20%;
            left: calc(100% - 180px);//-175px
            border-radius: 5px;
            color: #1b1c1e;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;

            @media (max-width: $cl-breakpoint-md - 1) {
                top: calc(160% - 22px);
            }

            a {
                cursor: pointer;
            }

            i {
                color: #d5d5d5;
                margin-right: 20px;
            }

            .cl_visible {
                color: #fc00b0;

                i {
                    color: #fc00b0;
                }
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                display: inline-block;
                left: 74%;
                border-style: solid;
                border-color: transparent;
            }

            &::before {
                top: -7px;
                border-width: 0 7px 7px;
                //border-bottom-color: #ccc;
                border-bottom-color: rgba(0, 0, 0, 0.2);
            }

            &::after {
                top: -6px;

                border-width: 0 6px 6px;
                border-bottom-color: #fff;
            }
        }

    }

    .cl_searchActionbar {
        background-color: $body-bgcolor;
        text-align: right;

        .toggle_button {

            label {
                cursor: pointer;
                color: #ccc;
                font-size: 10px;
                margin-right: 50px;
            }

            input {
                &[type="radio"] {
                    &.toggle {
                        display: none;

                        &:checked + label {
                            color: #1b1c1e;
                            border-color: transparent;
                        }

                        + label {
                            width: 3em;
                        }

                    }

                }
            }
        }

        .cl_searchBtn {
            background-color: #55dada;
        }
    }

    .cl_searchCont {
        padding: 0 23px;

        @media (min-width: $headerBreakingPoint) {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }

        .search_magnifier {
            cursor: pointer;
        }

        .search_bar {
            width: 90%;
            border: none;
            padding: 10px 0 10px 10px;
            background-color: $body-bgcolor;
            font-family: 'Mosk_Bold_700';
            font-size: 13px;

             @include placeholders{
                color: #d2d3d3;
             }
        }
    }

    .search_results {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 12px;
        //height: 332px;
        padding-bottom: 63px;
        position: absolute;
        top: 0px;
        left: 0;//-1px;
        right: 0;//-1px;
        z-index: 9999;
        background-color: $body-bgcolor;

        margin: 0;

        img {
            margin-right: 18px;
        }

        .cl_table_row {
            @include transition(all 0.3s ease);

            span,
            img {
                cursor: pointer;
            }

            img {
                &:hover {
                    @include transform(scale(1.05));
                }
            }
        }

        .cl_table_row span,
        .showMore_button {
            &:hover {
                -webkit-stroke-width: 5.3px;
                -webkit-stroke-color: #f3f8f9;
                -webkit-fill-color: #f3f8f9;
                @include text-shadow(0 0 10px #95939f)
            }
        }

        .search_button,
        .showMore_button {
            font-size: 9px;
        }

        .search_button {
            cursor: pointer;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            background-color: transparent !important;
            border: none;
            padding-bottom: 10px;
            @include box-shadow(0 1px #ff00b1);
            @include transition(all 0.07s ease-in);
        }

        .microphone_sing {
            margin-left: -5px;
        }

        $searchTables-distance: 35px;
        //tables
        .cl_songList {
            padding: 0 $searchTables-distance;
        }

        .cl_artistList,
        .cl_userList {
            padding: 0 $searchTables-distance 0 0;

            @media (max-width: $cl-breakpoint-md - 1px) {
                padding: 0 $searchTables-distance;
            }
        }

        .cl_artistList {
            @media (min-width: $cl-breakpoint-md) and (max-width: $cl-breakpoint-lg - 1px) {
                padding-left: $searchTables-distance;
            }
        }

        //table row columns
        .col_with_1,
        .col_with_3,
        .col_with_2,
        .col_with_6 {
            height: 75px;
            border-top: 1px solid #d2d3d3;
        }

        .col_with_1 {
            width: 5%;
        }

        .col_with_3 {
            width: 20%;
        }

        .col_with_2 {
            width: 10%;
        }

        .col_with_6 {
            width: 25%;
        }
    }

    //is this in use?
    .cl_searchField {
        color: gray;
        margin-left: 2px;
        font-size: 14px;
        height: 30px;
        width: 30%;
    }
}

.cl_wrap_img {
    .cl_image {
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    &:hover {
        cursor: pointer;
        //@include transform(scale(0.95));
        //@include box-shadow(15px 15px 20px #dfe0e2);

        .cl_image {
            @include opacity(0.95);
        }
    }
}

.cl_img_container,
.cl_wrap_img {
    @include transition(all 0.3s ease);
    cursor: pointer;

    .cl_img_hover {
        color: #fff;
        position: absolute;
        visibility: hidden;
        font-size: 26px;
    }

    &:hover {
        .cl_img_hover {
            visibility: visible;
            @include transform(scale(1.05));
            text-shadow: 2px 2px 2px #575757;
        }
    }
}

.cl_img_container {
    .cl_img_hover {
        top: 37px;
        left: 41px;
    }
}

.cl_songs_grid  {
    .cl_item_container {
        &:hover {
            z-index: 999;
        }

        .cl_single_item {
            padding-top: 36px;
            height: 100%;
            border-bottom: 1px solid #d0d1d3;
            padding-bottom: 17px;


            .duets_details {

                > span {
                    display: block;
                }
            }

            .cl_wrap_img {
                cursor: pointer;

                .cl_duets_img {
                    @include border-radius(4%);
                    height: 96px;
                    width: 96px;
                }

                .cl_img_hover {
                    height: 28px;
                    top: 35px;
                    left: 53px;
                }

                &:hover {

                    .cl_duets_img {
                        @include transform(scale(1.05));
                    }
                    .cl_img_hover {
                        visibility: visible;
                        @include transform(scale(1.05));
                        text-shadow: 2px 2px 2px #575757;
                    }
                }
            }
        }
    }
}

.history_tab {
    a {
        color: #ff457c;
        text-decoration: none;
    }

    table {
        border-collapse: separate;
        border-spacing: 0 1em;

        tr {
            background-color: #fff;

            td {
                padding-top: 1.4em;
                padding-bottom: 1.4em;
                border: none;
            }
        }
    }

    .red_flag {
        color: #ff457c;

        i {
            margin-left: 3px;
        }
    }
}

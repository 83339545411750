html {
    //margin-left: calc(100vw - 100%);
}

body {
    background-color: rgb(29, 25, 50);
}


//removing outline on focus for chrome etc
img {
    &:focus {
        outline: none;
    }
}

ul {
    list-style-type: none;
}

//to redesign
:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

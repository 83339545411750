.privacy_box {

    .privacy_tab {
        padding-left: 0;
        padding-right: 10%;
    }

    .family_tab {
        padding-left: 5%;
        padding-right: 5%;

        .form-group {
            height: 30px;
            position: relative;

            > label {
                position: absolute;
                top: 15%;
                height: 50%;
                left: 63px;//15%;
            }
        }
    }

    .btn_family {
        margin-top: 0;
    }

    .icon_offset {
        //left: 260px !important;
    }

    .cl_checkbox-views {
        display: none;
    }

}


#purePopupWrap * {
    margin: 0;
    padding: 0;
}

#purePopupWrap {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    background-color: rgba(0, 0, 0, 0.1);
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
}

#purePopupWrap.open {
    display: block;
}

#purePopupWrap.open.pop {
}

#purePopupWrap > div {
    position: fixed;
    z-index: 99999;
    left: 50%;
    top: 20%;
    margin-left: -150px;
    width: 300px;
    padding: 20px;
    background-color: #fff;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    -webkit-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -ms-transform: scale(0.2);
    transform: scale(0.2);

    border-radius: 8px;
}

#purePopupWrap.pop > div {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

#purePopupWrap .purePopupTitle {
    text-align: center;
    font-size: 18px;
}

#purePopupWrap input {
    display: block;
    padding: 2px;
    margin: 4px 0;
    font-size: 14px;
    outline: none;
    border: 1px solid #ddd;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

#purePopupWrap input:focus {
    border: 1px solid #29ABF7;
}

#purePopupWrap .purePopupButton {
    float: right;
    padding: 8px;
    margin-left: 10px;
    border-radius: 6px;
    cursor: pointer;

    user-select: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#purePopupWrap ._ok_ {
    background-color: #3baf73;
}

#purePopupWrap ._cancel_ {
    background-color: #a33;
}

#purePopupWrap .purePopupButton:hover {
    opacity: 0.8;
}

#purePopupWrap .purePopupButton:active {
    opacity: 1;
}

#purePopupWrap .input-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
}

@import '../vars';
@import 'styles/mixins';

// singsnap common content blocks
.ssc {
  &-wrapper {
    display: flex;
    height: 100%;

    .song-info {
      display: flex;
      align-items: center;
      min-width: 300px;

      .song-poster {
        border: 1px solid #ccc;
        border-radius: 3px;
        margin-right: 9px;
        width: 55px;
        height: 55px;
        background-size: cover;
        background-position: center center;
      }

      .song-detail h4 {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        margin-bottom: 3px;

        &.song-author {
          font-weight: 400;
          color: #90919d;
        }
      }
    }
  }

  &-list-content {
    flex: 1 1 1px;
  }

  &-sidebar {
    width: 309px;
    margin-left: 27px;
    background: white;
    padding: 20px 15px;
    height: 100%;
    border-radius: 6px;

    &-actions {
      padding-left: 0;

      li {
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-gap: 5px;
        align-items: center;
        height: 40px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        font-family: $Roboto;
        padding-left: 10px;

        &:hover {
          background: #f5f6f7;
        }

        > :first-child {
          opacity: 0.4;
          text-align: center;
        }
      }
    }
  }

  &-btn-new-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    > span {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .upload-icon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #58c7c9;
      margin-right: 10px;
    }
  }

  &-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;

    h1 {
      font-family: $poppins;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      margin: 0;
    }

    .header-left {
      display: flex;
      align-items: center;
    }
  }

  &-search-holder {
    max-width: 339px;
    margin-left: 20px;
    width: 100%;
  }

  &-list-title {
    border-radius: 6px 6px 0 0;
    background: #21243c;
    padding: 18px 15px;
    min-height: 48px;

    h2 {
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      color: white;
      margin: 0;
    }
  }

  &-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 15px 15px;
    background: white;

    &-narrow {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .song-date {
      font-family: Roboto, sans-serif;
      display: flex;
      align-items: center;
      margin-right: 5%;

      > span {
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .song-type {
      font-family: Roboto, sans-serif;
      opacity: 0.7;
      font-size: 12px;
    }

    .song-actions {
      display: flex;
      align-items: center;
      cursor: pointer;

      .btn-record {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #58c7c9;
        margin-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-clickable {
        @include flex-between;
        cursor: pointer;
        flex: 1 1 1px;
      }
    }
  }

  &-list-actions {
    display: flex;
    align-items: center;
  }

  &-round-form {
    padding: 12px;
    background-color: #dbf0f0;
    border-radius: 6px;
  }

  &-page-header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;

    h3 {
      font-family: $poppins;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      font-size: 13px;
      margin-bottom: 40px;
      color: $color-gray-30;
    }
  }
}

.app-icon {
  font-size: 0;
}

.ssc-list-item {
  .ssc-title-options .dropdown-toggle {
    margin: 0;
  }
}

.night-theme {
  .ssc-list-item {
    background-color: inherit;
  }

  .ss-featured-list--item:nth-child(2n) {
    background-color: $night-main-blue-alt;
  }

  .ssc-sidebar {
    // background: $night-main-blue-alt;
    color: $white;
  }
}

.three-dot-placeholder {
  width: 42px;
}

.ss-step-title {
  font-family: $poppins;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 50px auto 40px;
}

.ss-store-page {
  min-height: calc(100vh - 60px - 66px);
  display: grid;
  grid-template-rows: 1fr auto;

  &-footer {
    padding: 40px 0;
    background: rgb(255, 255, 255);
    text-align: center;

    p {
      font-size: 12px;
      margin: 0;

      a {
        color: rgb(88, 199, 201) !important;
        font-weight: bold;
        cursor: pointer;
      }

      span {
        color: rgb(143, 141, 145);
      }
    }
  }

  .btn-ctas {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .btn-custom-step {
      margin-right: 0;
    }
  }
}

.ss-step-nav {
  margin-left: auto;
  margin-right: auto;

  &-4 {
    max-width: 960px;
  }

  &-2 {
    max-width: 400px;
  }

  &-3 {
    max-width: 700px;
  }
}

.ss-store-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, 265px);
  grid-column-gap: 30px;
  justify-content: center;
}

.ss-store-wrapper {
  padding: 20px 30px 50px;

  .ss-store-container {
    max-width: 960px;
    margin: 0 auto;
  }

  .ss-store-form {
    max-width: 800px;
    padding: 25px;
    margin: 0 auto;

    .btn-ctas {
      margin-top: 80px;
    }
  }
}

.ss-store-box {
  &-footer {
    .btn {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.ss-glow-box {
  border: 6px solid rgb(218, 240, 240);
  border-radius: 3px;
  box-shadow: 1px 1px 14px 3px #0000001f;
  margin: 0 0 30px;
  background: white;
  transition: border-color ease 0.3s;

  &:hover {
    border-color: rgb(88, 199, 201);
  }
}

.ss-payment-options {
  .ss-store-option-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 20px;
    display: grid;
    grid-template-columns: 1fr auto;

    .ss-payment-logo {
      height: 20px;
    }
  }

  .ss-store-option-description {
    color: #262628;
    opacity: 0.7;
    font-size: 13px;
  }

  .ss-store-option-body {
    ul {
      list-style: disc;
      color: #262628;
      opacity: 0.7;

      li {
        padding-left: 15px;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
  }
}

.ss-payment-method-details {
  margin-left: -35px;
}

.ss-order-details {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 300px;

  .ss-order-summary {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-gap: 15px;

    h5 {
      font-size: 20px;
      font-family: $poppins;
      margin-bottom: 30px;
      font-weight: 600;
    }

    .summary-total {
      padding: 15px 0;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
    }

    p {
      color: #262628b2;
      font-size: 13px;
      line-height: 1.5;

      strong {
        color: #262628;
      }

      span {
        font-size: 20px;
        font-weight: bold;
        color: rgb(88, 199, 201);
      }

      a {
        color: rgb(88, 199, 201);
        text-decoration: none;
      }
    }
  }

  .ss-order-description {
    font-style: italic;
  }
}

.grid-row {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 20px;
}

.ic-detail-item {
  font-family: $roboto;

  h5 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  p {
    font-size: 13px;
    color: $color-text-sub;
    margin: 0;
  }

  h6 {
    font-size: 13px;
    color: $color-text-main;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.recipient-list-box {
  .recipient-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;

    > span {
      font-weight: bold;
      text-transform: uppercase;
      opacity: 0.7;
      font-size: 13px;

      &.recipient-add {
        cursor: pointer;
        opacity: 1;

        i {
          color: $color-glowing;
          margin-right: 10px;
        }
      }
    }
  }

  .recipient-box-content {
    padding: 10px;

    .recipient-empty {
      font-size: 13px;
      font-weight: bold;
      opacity: 0.7;
      text-align: center;
      padding: 20px;
    }

    .user-list {
      max-height: 300px;
      min-height: 0;
    }

    app-icon-trash {
      cursor: pointer;
    }
  }
}

.ngucarousel-custom {
  .ngucarousel {
    overflow: hidden;

    > .ngucarousel-inner {
      margin-left: -15px;
      margin-right: -15px;

      > .ngucarousel-items > .item {
        flex: 0 0 200px !important;
      }
    }
  }
}

@mixin flex() {
  display: flex;
  align-items: center;
}

@mixin flex-center() {
  @include flex;
  justify-content: center;
}

@mixin flex-between() {
  @include flex;
  justify-content: space-between;
}

@mixin absolute-inner() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin app-font($family, $size, $height: 1, $weight: normal, $style: normal) {
  font-family: $family;
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
  font-style: $style;
}

$xl: "only screen and (max-width: 1249px)";
$lg: "only screen and (max-width: 1199px)";
$md: "only screen and (max-width: 991px)";
$sm: "only screen and (max-width: 767px)";
$xs: "only screen and (max-width: 575px)";
$sm-min: "only screen and (min-width: 768px)";
$lg-min: "only screen and (min-width: 1200px)";

$Roboto: 'Roboto', sans-serif;
$Poppins: 'Poppins', sans-serif;

.social_box {
    .social_tab {
        padding-left: 0;

        div {
            display: inline-block;
            border-radius: 19rem;
            height: 36px;
            width: 340px;
            margin-bottom: 20px;
            padding-right: 3px;
            background-color: white;

            i {
                height: 26px;
                width: 20px;
                padding: 10px 0px;
                margin-right: 10px;
            }

            span {
                line-height: 36px;
                font-size: 0.8rem;
                color: #939594;
            }
        }
    }
}

.notify_box {
    .notify_tab {
        padding-right: 10%;
        padding-left: 0;
    }

    .icon_offset {
        //left: 260px !important;//37%
    }
}

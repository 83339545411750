
/** inputs **/

.input-ghost {
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(38, 38, 40, 0.1);
    border-radius: 0;
    padding: 11px 0;
    color: #212223;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    &:hover {
        outline: none;
    }
}

.input-label {
    text-transform: uppercase;
    color: rgb(144, 145, 157);
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 13px;
    text-align: left;
}

.input-label-float {
    color: #58C7C9;
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    text-align: right;
    position: absolute;
    right: 16px;
    bottom: 16px;
    cursor: pointer;
}

.ss-input {
    border: 1px solid #EFEFF1;
    border-radius: 3px;
    color: #90919D;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
}

.modal-content {
  border-radius: 20px;
}

.ssc-modal {
  .modal-dialog {
    max-width: 557px;
  }

  &-sm {
    .modal-dialog {
      max-width: 420px;
    }
  }

  .modal-header {
    padding: 20px 15px;
    border-bottom: none;

    h4 {
      font-size: 12px;
      font-weight: bold;
    }

    .close {
      padding: 0;
      margin: 0;
      font-size: 0;
    }
  }

  .modal-body {
    padding: 0 15px;

    &-content {
      padding: 20px 0;
      border-top: 1px solid #EFEFF1;
    }

    .input-group {
      margin-bottom: 10px;
    }

    .form-control {
      border: 1px solid #EFEFF1;
      border-radius: 3px;
      font-size: 12px;
      padding: 14px 20px;
      line-height: 1;
      height: auto;

      &::placeholder {
        color: #90919D;
      }
    }
  }

  .modal-footer {
    padding: 0;
    border-top: none;

    button {
      width: 100%;
     // border-radius: 0 0 6px 6px;
      padding: 15px;
      border-radius: 0 0 15px 15px;
    }
  }
}
.ss-modal-confirmation{
  .modal-content{
    max-width: 400px;
  }
  .modal-header, .modal-footer {
    border: none
  }
  .modal-title {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}

.ss-modal-fullscreen {
  .modal-dialog {
      margin: 0;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: none;
      height: 100%;
  }

  .modal-content {
      max-width: none;
      height: 100%;
      border: none;
      border-radius: 0;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
}

.ss-warning-modal.modal {
  background-color: rgba(0, 0, 0, 0.5);

  .modal-body {
    padding: 60px 55px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      color: #262628;
      font-family: $poppins;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      margin: 60px 0;
    }

    .block-message {
      color: #90919D;
      font-family: $roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin: 40px 0;
      text-align: center;
    }

    .btn {
      min-width: 117px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .modal-actions {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
  }

  .modal-dialog {
    top: 50%;
    transform: translate(0, -75%);
    max-width: 400px;
  }

  .message {
    color: rgb(144, 145, 157);
    font-weight: 300;
    text-align: center;
    font-size: 14px;
    line-height: 24px;

    strong {
      color: #000;
    }
  }
}

.ss-share-modal.modal {
  .modal-body {
    padding: 18px 12px;
  }
}

.modal-dialog {
    margin-top: 15%;
    z-index: 9999;
    // overflow-x: hidden;

    .modal-body {
        text-align: center;
    }

    &.cl_succsesDialog,
    &.cl_confirmDialog,
    &.cl_inviteDialog {

        .cl_img {
            height: 100%;
            width: 100%;
        }

        figure {
            margin: 0;
        }

        a {
            overflow: hidden;
            display: inline-block;
            width: 100px;
        }

        @media screen and (min-width: 50em) {
            a {
                width: 100px;
            }
        }
    }

    &.cl_confirmDialog,
    &.cl_inviteDialog {
        .cl_decline-btn,
        .cl_confirm-btn,
        .cl_btn {
            background: transparent;
            @include border-radius(50%);
            @include transition(all 0.02s ease-in);
            height: 50px;
            width: 50px;
            cursor: pointer;

            &:focus {
                outline: none;
            }

            &:hover {
                border-width: 3px;
            }

            &:active {
                @include transform(translateX(0%) scale(0.95));
            }
        }

        .cl_confirm-btn {
            margin-right: 25px;
            border: 2px solid #56d9d8;

            i {
                color: #56d9d8;
            }
        }

        .cl_decline-btn {
            margin-left: 25px;
            border: 2px solid red;

            i {
                color: red;
            }
        }

        .cl_song-img {
            height: 213px;
            width: 211px;
            @include box-shadow(20px 19px 45px #8f8f93);
        }

        .cl_action-container {
            height: 50%;

            @media (max-width: $cl-breakpoint-sm - 1) {
                overflow: auto;
            }

            > div {

                &.col-sm-3 {
                    @media (max-width: $cl-breakpoint-sm - 1) {
                        -webkit-box-flex: 0;
                        -webkit-flex: 0 0 50%;
                        -ms-flex: 0 0 50%;
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }

        }

    }

    &.cl_cratePlayList,
    &.cl_addToPlaylist {
        .modal-content {
            .cl_closeIcon {
                position: absolute;
                top: 25px;
                right: 25px;
                cursor: pointer;
            }
            .cl_song-data {
                img {
                    border-radius: 5px;
                    padding: 0;
                    width: 210px;
                    @include box-shadow(20px 19px 45px #8f8f93);
                }
            }

            .cl_song-data {
                text-align: left;
                font-family: 'Montserrat', sans-serif;
                .cl_song-text {
                    margin-top: -23px;

                    span {
                        display: block;
                    }

                    .cl_song-name {
                        font-size: 26px;
                    }

                    .cl_artist {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &.cl_succsesDialog,
    &.cl_confirmDialog {

        .modal-content {
            width: 512px;

            @media (max-width: $cl-breakpoint-sm - 1) {
                width: 100%;
            }

            .cl_title {
                white-space: normal;
                width: 100%;
            }
        }

        .cl_song-name {
            margin-top: 38px;
            font-weight: bold;
            font-size: 20px;
        }

        .cl_artist {
            margin-top: 12px;
            font-size: 13px;
        }
    }

    //individual classes
    &.cl_confirmDialog {
        .modal-content {
            //height: 338px;
            width: 512px;

            @media (max-width: $cl-breakpoint-sm - 1) {
                width: 100%;
                height: 100%;
            }
        }

        .cl_title {
            color: gray;
            font-size: 20px;
        }

        .cl_song-name {
            margin-top: 38px;
            font-weight: bold;
            font-size: 20px;
        }

        .cl_artist {
            margin-top: 12px;
            font-size: 13px;
        }

        .cl_action-container {
            margin-top: 30px;
        }

        .playlist_collage_item {
            @include box-shadow(20px 19px 45px #8f8f93)
        }

        .cl_decline-btn,
        .cl_confirm-btn,
        .cl_btn {
            margin-top: 40%;
        }

        .cl_images_container {
            line-height: 0.5px;
        }
    }

    &.cl_succsesDialog {

        .modal-content {
            height: 338px;
            width: 512px;

            @media (max-width: $cl-breakpoint-sm - 1) {
                height: 100%;
            }

            .modal-body {

                .img {
                    height: 213px;
                    width: 211px;
                    @include box-shadow(20px 19px 45px #8f8f93);
                    margin-top: -70px;
                    text-align: center;
                }

                .cl_collage {
                    margin-left: 27%;
                    line-height: 0;
                }

                .cl_song-name {
                    margin-top: 38px;
                    font-weight: bold;
                    font-size: 20px;
                }

                .cl_artist {
                    margin-top: 12px;
                    font-size: 13px;
                }

                .cl_confirm-message {
                    color: #53d9da;
                    font-size: 24px;
                    margin-top: 20px;
                }
            }
        }

    }

    &.cl_cratePlayList {
        left: -10%;

        @media (max-width: $containerWidth - 1) {
            left: 0;
            max-width: 80%;
        }

        @media (max-width: $cl-breakpoint-sm - 1) {
            max-width: 100%;
        }

        .modal-content {
            height: 400px;
            width: 900px;

            @media (max-width: $containerWidth - 1) {
                width: 100%;
            }

            @media (max-width: $cl-breakpoint-sm - 1) {
                height: 100%;
            }

            .cl_close-container {
                text-align: right;
                padding-right: 72px;

                .cl_closeModal {
                    cursor: pointer;
                    font-size: 18px;
                    text-align: right;
                    margin-top: 40px;
                }
            }

            .cl_song-data {
                padding: 20px;

                img {
                    margin-top: -115px;
                }
            }
        }

        form {
            position: relative;
            @include border-radius(19rem);
            height: 100%;
            width: 100%;
            padding: 0;

            button {
                position: absolute;
                right: 5px;
                top: 5px;
                @include border-radius(19rem);
                background-color: #f800b1;
                color: #fff;
                height: 62px;
                width: 25%;
                font-size: 1.4rem;
                letter-spacing: 1.4px;
                cursor: pointer;

                @media (min-width: $cl-breakpoint-sm) and (max-width: $cl-breakpoint-md - 1) {
                    width: 30%;
                }

                @media (max-width: $cl-breakpoint-sm - 1) {
                    width: 50%;
                }
            }

            input {
                @include border-radius(19rem);
                font-size: 1.7rem;
                font-family: inherit;
                letter-spacing: 1px;
                border: none;
                height: 100%;
                width: 100%;
                padding-left: 30px;

                &:focus {
                    border: none;
                    @include box-shadow(0 0 3px #5CDAD9);
                    outline: none;
                }
            }
        }

        .cl_input_container {
            height: 75px;
            border: 1px solid darkgray;
            @include border-radius(40px);
            text-align: center;
            vertical-align: middle;

            &.cl_noImgCont {
                margin: 120px 45px 0 45px;
            }

            &.cl_imgCont {
                margin: 70px 45px 0 45px;
            }
        }

        input {
            @include placeholders {
                color: #CDCFCE;
                @include opacity(1);
            }
        }
    }

    &.cl_inviteDialog {

        .modal-content {
            max-height: 355px;
            max-width: 512px;

            height: 100%;
            width: 100%;

            @media (max-width: $cl-breakpoint-sm - 1) {
                max-height: 100%;
            }
        }

        .cl_avatar {
            cursor: pointer;
            @include border-radius(50%);
            height: 100%;
            width: 100%;
        }

        .cl_avatar_box {
            height: 65px;
            width: 65px;
            margin: 0 auto;
            margin-top: -10%;

            img {
                padding: 0;
                width: 100%;
            }
        }
        .cl_song-name {
            margin-top: 20px;
            font-weight: bold;
            font-size: 20px;
        }

        .cl_artist {
            font-size: 13px;
        }

        .cl_invite_name {
            color: #F61CB0;
            display: block;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 1.8px;
            margin-top: 10px;
        }

        .cl_invite_text {
            font-size: 1.5rem;
            letter-spacing: 1.5px;
            font-weight: 300;
            display: block;
        }

        .cl_action-container {
            margin-top: 10px;
        }

        .cl_decline-btn,
        .cl_confirm-btn,
        .cl_btn {
            margin-top: 52%;

            @media (max-width: $cl-breakpoint-sm - 1) {
                margin: 5% 0;

            }
        }

        .cl_img {
            @include box-shadow(20px 19px 45px #8f8f93);
        }
    }

    &.cl_addToPlaylist {

        ::-webkit-scrollbar {
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            @include border-radius(7px);
            @include box-shadow(inset 0 0 6px rgba(0, 0, 0, 0.5));
        }

        .modal-content {

            @media (min-width: 576px) {
                //  max-width: 80vw;
                //  margin: -20vw;
                width: 80vw;
                margin: auto !important;
            }

            @media (min-width: $modalDialogContentWidth) {
                height: 525px;
                width: 1050px;
                margin-left: calc(50% - calc(#{($modalDialogContentWidth - $sidebarWidth)}  / 2)) !important;
            }

            @media (max-width: 1300px) {
                // width: calc(100vw - #{$sidebarWidth});
                // margin-left: calc(50% - (100vw - #{$sidebarWidth}));
                width: 950px;
                //margin-left: calc(50% - #{($modalDialogContentWidth - $sidebarWidth)  / 2}) !important;
                margin-left: calc(50% - calc(#{($modalDialogContentWidth - $sidebarWidth)}  / 2)) !important;
            }

            .cl_header {
                padding: 70px 65px 0 18px;
            }

            .cl_existing-playlist-container {
                margin-top: 30px;
                padding: 0 30px;
                overflow: auto;
                height: 325px;
            }

            .cl_create-new {
                text-align: right;
                color: #5fd4db;
                font-size: 20px;

                i {
                    cursor: pointer;
                    margin-right: 10px;
                }

                span {
                    cursor: pointer;
                }
            }

            .cl_song-data {
                img {
                    margin-top: -140px;
                }
            }
        }
    }
}

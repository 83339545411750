@import 'vars';

.night-theme {
  app-icon-more-dot use,
  app-icon-sort-alpha-down path {
    fill: white;
  }
}

.night-theme {

  /*
  scrollbar color
  */
  .ps__thumb-y {
    background-color: #1d1d1df5;
  }

  .main,
  .featured-list--container {
    background-color: $night-main-blue !important;
  }

  .ms-container,
  .ms-actions,
  .ms-pagination,
  .ms-topic,
  .comments,
  .ms-topic-create,
  .form-action,
  .my-comments-container,
  .ms-topic-create form > .form-group:nth-child(1),
  .ms-topic-create form > .form-group:nth-child(1) + div,
  .dropdown-menu,
  .dropdown-orderby,
  .report,
  .featured-list--item:nth-child(2n),
  app-datetime-picker,
  ngb-datepicker,
  .content,
  .inner-circle-content .input-box,
  .inner-circle-box,
  .add-member-modal .modal-body,
  .add-member-modal .modal-footer,
  .ss-modal-confirmation .modal-content,
  .profile-menu,
  .profile-content,
  .recording-body-details {
    background-color: $night-main-blue !important;
  }

  .comments-header,
  .ms-actions .btn,
  .ss-form-input,
  .ss-form-input input,
  .ql-editor,
  .ql-toolbar.ql-snow,
  .autocomplete,
  .autocomplete input,
  .ss-form-input-select select,
  .cover-dropzone .content,
  .comment,
  .ms-topic-body,
  .report textarea,
  .report select,
  input.form-control,
  select.custom-select,
  .my-comment,
  .featured-list--header,
  .search-box,
  .btn-custom-step-gray:hover,
  .contest-upload-thumbnail,
  .contest-start,
  .contest-round-recordings .ss-option,
  .contest-rounds-options > div,
  .ss-form-input.ss-form-input-textarea textarea,
  .join-options,
  .edit-area,
  .featured-list--item:nth-child(2n + 1),
  .inner-circle-members-list li:nth-child(2n + 1),
  .inner-circle-members-box h6,
  .list-header__content,
  .content-box--title,
  .simple-list--title,
  .featured-list--container h2 {
    background: $night-main-blue-alt !important;
  }

  .autocomplete,
  .comment,
  .ss-form-input-select select,
  .ms-topic-body,
  .reply-comment,
  input.form-control,
  .my-comment,
  select.custom-select {
    border: 1px solid $night-border !important;
  }

  .btn.btn-link,
  .ms-actions .btn,
  .ss-form-input input,
  .ql-editor span,
  .ql-editor,
  .ql-editor p,
  .autocomplete,
  .autocomplete input,
  .ss-form-input-select select,
  app-dropzone label,
  .cover-dropzone .content,
  .comment,
  .ql-editor-styles,
  .ql-editor-styles p,
  .ql-editor-styles span,
  .comment-footer-label,
  app-time-stamp span,
  .ms-topic-action-reply,
  .dropdown-menu,
  .report h4,
  .report textarea,
  .ms-topic-action__label,
  .my-comment-author,
  .my-comment-actions-buttons,
  .my-comment-recording-author-info h6,
  .comment-action__icon span,
  input.form-control,
  select.custom-select,
  .dropdown-item,
  .dropdown-item h6,
  .dropdown-orderby,
  .big-title,
  .my-comments-inner-container h1,
  .nav-header span,
  .users-holder h4,
  textarea,
  .featured-list--no-results span,
  .contest-board > div,
  .select-title,
  .form-control-plaintext,
  .ss-option .ss-option-label,
  .ss-user--actual-name,
  .ssc-list-header h1,
  .contest-join,
  .host,
  .contest-options p,
  .contest-action,
  .contest-name,
  .contest-search h1,
  .ss-contest--info .info-name,
  .ss-round--info-title,
  .type-header span,
  .type-header label,
  .contest-search-results h1,
  .info-title,
  .my-comment-actions span,
  .my-comment-actions .comment-action,
  .inner-circle-title,
  .back-title,
  .input-box-switches,
  app-char-counter span,
  .inner-circle-single-actions li,
  .add-member-modal .modal-body,
  .add-member-modal .modal-footer,
  .ss-modal-confirmation .modal-content,
  .profile__tab-nav li.active a,
  .featured-list--item h1,
  .recording-counter,
  .profile__tab-nav li a:hover,
  .profile__tab-nav li a:focus,
  .profile__tab-nav li a:active {
    color: $white !important;
  }

  .ms-topic__close app-icon-close use,
  app-icon-more-dot use,
  app-time-stamp use,
  .type-header app-icon use {
    fill: $white !important;
  }

  .theme-bg {
    background-color: $night-main-blue;

    h1,
    h2,
    p {
      color: $white !important;
    }
  }

  .ms-list {
    background: $night-main-blue !important;
  }

  .ms-list-item {
    h3, p {
      color: $white !important;
    }
  }

  li.page-item.active a.page-link,
  .page-item.disabled .page-link {
    background: $night-main-blue-alt !important;
  }

  li.page-item a.page-link {
    color: $white !important;
  }

  .page-item:first-child .page-link, .page-item:last-child .page-link {
    background-color: $night-main-blue-alt;
  }

  span.r-type-name {
    color: $white !important;
  }

  span.r-type-name.active {
    color: #cfbbcf !important;
  }

  .dropdown-item svg use {
    fill: white;
  }

  .dropdown-item:hover,
  .dropdown-menu-content:not(.dropdown-menu-content--contest):hover,
  .dropdown-menu-content:not(.dropdown-menu-profile-recordings):hover,
  .dropdown-menu-content > div:not(.dropdown-menu-content):hover {
    background-color: $night-main-blue-alt !important;
  }

  .ssc-modal {
    .modal-body,
    .modal-header {
      background: $night-main-blue;
      color: $white;
    }

    .form-control {
      background: $night-main-blue-alt;
      border-color: $night-border !important;
    }
  }

  .featured-list--item:hover {
    background: $night-hover-color !important;
  }

  app-datetime-picker,
  ngb-datepicker,
  hr,
  .add-member-modal .modal-footer {
    border-color: $night-border !important;
  }

  .profile-menu {
    border: 1px solid $night-border !important;
  }

  .ql-editor-styles .mention span,
  .ql-editor a.mention span {
    color: $dark-blue !important;
  }

  .ql-editor-styles .mention,
  .ql-editor a.mention {
    background-color: rgb(95, 191, 196);
  }
}

.ss-btn-primary {
    border-radius: 6px;
    background-color: #58C7C9;
    color: #fff;
    font-family: roboto, sans-serif;
    font-size: 12px;
}

.ss-btn-ghost {
    border-radius: 6px;
    border: 1px solid rgba(144, 145, 157, 0.4);
    background-color: #fff;
    font-family: roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    &.ss-btn-danger {
        color: #F94678;
        border-color: rgba(249, 70, 120, 0.3);
    }
}

.ss-btn-big {
    padding: 8px 20px;
    min-width: 90px;
}

.btn-primary {
    background-color: #58C7C9;
    color: #fff;
    border-color: #58C7C9;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 8px 10px;
    &:hover {
        background-color: rgba(88, 199, 201, 0.80);
        border-color: rgba(88, 199, 201, 0.80);
    }
}

.btn-secondary {
    font-size: 12px;
    letter-spacing: 1px;
    padding: 8px 10px;
}

.ss-btn-primary-inverse {
    background-color: #fff;
    color: #58C7C9;
    border-color: #58C7C9;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 8px 10px;
    &:hover {
        background-color: rgba(88, 199, 201, 0.80);
        color: #fff;
        border-color: rgba(88, 199, 201, 0.80);
    }
}

.btn-back {
    color: rgb(0, 0, 0);
    border-radius: 50%;
    padding: 0;
    height: 30px;
    width: 30px;
    line-height: 16px;
    font-size: 16px;
    text-align: center;
    text-indent: -1px;
    box-sizing: border-box;
}
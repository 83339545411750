.side_bar {
    background-color: $page-bgcolor;
    color: $color-secondary;
    // height: 100%;
    padding-right: 20px;

    .nav-item {
        > .btn-group {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .singsnap_logo {
        display: block;
        vertical-align: middle;
        margin-bottom: 30px;
        color: #fff;
        font-family: Mosk_Bold_700;
        font-size: 19.76pt;
        @include transition(all 0.07s ease-in);

        .logo {
            margin-right: 5px;
        }

        ._singsnap {
            vertical-align: middle;
        }

        &:hover {
            -webkit-stroke-width: 10.3px;
            -webkit-stroke-color: #fff;
            -webkit-fill-color: #fff;
            @include text-shadow(0 0 10px #fff);
        }
    }

    a,
    .tab_button {
        color: $color-secondary;
        @include transition(all 0.07s ease-in);

        &:hover {
            -webkit-stroke-width: 10.3px;
            -webkit-stroke-color: #fff;
            -webkit-fill-color: #fff;
            color: #fff;
        }

        &:focus {
            color: #fff;
        }
    }

    .tab_button {
        font-family: 'Montserrat', sans-serif;
        font-size: $font-size-nav;
        background-color: transparent !important;
        border: none;
        border-bottom: 1px solid $color-secondary;
        cursor: pointer;
        padding-bottom: 10px;
        margin-bottom: 30px;

        &:focus,
        &.cl_active {
            outline: 0 !important;
        }

        &.cl_active {
            @include box-shadow(inset 0 -3px $color-glowing);
            color: #fff;
        }

        &:focus {
            @include text-shadow(inset 0 -3px $color-glowing);
        }
    }

    a {
        text-decoration: none;
    }

    .sideBar_link {
        font-family: 'Montserrat', sans-serif;
        margin: 15px 0;
        font-size: $font-size-nav;
    }

    .horizontal_ruler {
        margin: 50px 0 10px 0;
        border-bottom: 1px solid $color-secondary;
    }

    .awesome_icons {
        font-size: 18px;
        margin-right: 13px;
    }

    .cl_playlists,
    .cl_show_more {
        margin-left: 30px;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
    }

    .cl_playlists {
        margin-top: 8px;
        font-size: $font-size-navSmall;
        font-weight: 400;
        color: #fff;

    }

    .cl_show_more {
        margin-top: 10px;
        font-size: $font-size-navSmall-more;
        color: $color-glowing;
    }
}

.cl_artist_grid,
.cl_song_grid {
    overflow: auto;
    padding-top: 16px;
    position: relative;

    .cl_genres-song,
    .cl_sing-song {
        margin: 18px 0 23px;

        .cl_img_microphone_hover,
        .cl_image {
            border-radius: 5px;
        }

        span {
            display: block;
            cursor: pointer;

            &:hover:not(.cl_date) {
                -webkit-stroke-width: 5.3px;
                -webkit-stroke-color: $body-bgcolor;
                -webkit-fill-color: $body-bgcolor;

                text-shadow: 0px 0px 10px #95939f;
            }

            &:active:not(.cl_date) {
                transform: translateX(0%) scale(0.98);
                color: $color-light;
            }

        }
        .cl_artist {
            font-size: 10px;
        }
    }

    .cl_wrap_img {
        margin-bottom: 10px;

        .cl_image {
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }

        &:hover {
            @include transform(scale(0.95));
            @include box-shadow(15px 15px 20px #dfe0e2);

            .cl_image {
                @include opacity(0.95);
            }

            .cl_img_hover {
                visibility: visible;
                text-shadow: 2px 2px 2px #575757;
            }

        }

        .cl_img_hover {
            max-height: 35px;
            max-width: 35px;
            position: absolute;
            top: 43%;//45%;
            left: 45%;
            visibility: hidden;
        }
    }
}

.cl_song_grid {
    .cl_wrap_img {
        &:hover {
            @include box-shadow(15px 15px 20px #dfe0e2);
        }
    }
}

.cl_artist_grid {
    text-align: center;
    padding: 16px 15px 0 15px;

    > div[class^='col-'] {
        min-height: 245px;
        padding: 20px 0 0 0;
        @include transition(all 0.3s ease);
        cursor: pointer;

        img {
            height: 135px;
            width: 135px;
            border-radius: 50%;
            margin-bottom: 10px;
        }

        span {
            display: block;
        }

        &:hover {
            @include transform(scale(1.05));
            @include box-shadow(15px 15px 20px #dfe0e2);
            background: #fff;

            .cl_recordings {
                visibility: visible;
            }
        }

        .cl_recordings {
            visibility: hidden;
            font-size: 10px;
            color: $color-glowing-light;
        }
    }
}

.cl_genre_grid {
    overflow: auto;
    padding-top: 16px;

    div[class^='col-'] {
        margin: 25px 0;

        .cl_hoverCmp {
            position: absolute;
            z-index: 99;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            text-align: center;

            span {
                display: block;
                position: absolute;
                color: white;
                top: 70%;
                width: 100%;

            }
            .cl_icon_hover {
                position: absolute;
                display: block;
                top: 20%;
                left: 50%;
                margin-left: -25px;
            }
        }
    }
}

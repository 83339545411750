h2 {
    margin-top: 3px;
}
.sub_box {
    .sub_payment {
        height: 100%;
        margin-top: 55px;
        background-color: #fff;
        padding: 15px 6% 0 6%;
        .payment_info {
            b {
                font-size: .8em;
                display: block;
                margin-top: 25px;
                margin-bottom: 15px;
            }
        }
        .heading {
            padding-top: 15px;

            i {
                margin-left: -12%;
                font-size: 36px;
                font-weight: 900;
            }
        }
    }

    .sub_history {
        padding: 70px 0 0 5.2%;

        @media (max-width: $cl-breakpoint-lg - 1) {
            padding-left: 0;
        }
    }

    td {
        padding-left: 30px;
    }

    a,
    .paid,
    .fa-check-circle,
    .fa-check {
        color: #5ad7d9;
    }

    .sub_box {

    }

    .card_number {
        padding-top: 10px;
        font-size: 1.1em;
    }

    .end_p {
        margin-bottom: 30px;
        clear: both;
        cursor: pointer;
    }
    img {
        width: 53px;
        height: 38px;
    }
}

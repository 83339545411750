.recordings_details,
.duets_details {
    cursor: pointer;
    @include transition(all 0.02s ease-in);

    .cl_song,
    .cl_artist,
    .cl_author {

        &:hover {
            -webkit-stroke-width: 5.3px;
            -webkit-stroke-color: #f3f8f9;
            -webkit-fill-color: #f3f8f9;
            @include text-shadow(0px 0px 10px #95939f);
        }

        &:active {
            @include transform(translateX(0%) scale(0.98));
            color: #a2a0ab;
        }
    }

    .cl_author {
        span  {
            color: #ff00b1;
        }
    }

    .cl_date {
        cursor: auto;
    }
}

.cl_recordings_grid,
.cl_duets_grid {
    height: auto;
    width: 100%;

    .recordings_details,
    .duets_details {
        margin-top: 10px;

        > span {
            display: block;
        }
    }
}

.cl_home_body {
    .cl_duets_grid {
        .floating-box {
            @media (min-width: $cl-breakpoint-lg){
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 100%;
                -ms-flex: 0 0 100%;
                 flex: 0 0 100%;
                 max-width: 100%;
            }

            @media (min-width: $cl-breakpoint-xl) {
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 50%;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}

.cl_contest .cl_featured_container,
.cl_contest_container {
    margin-top: 20px;

    .cl_featured_row,
    .cl_contest_row {
        justify-content: center;
        -webkit-justify-content: center;

        .row {
            padding-top: 10px;
        }

        > span[class^='col-'],
        .cl_contest_item {
            margin-bottom: 20px;

            .cl_wrap_img {
                cursor: pointer;

                &:hover {
                    @include transform(scale(1));
                    @include box-shadow(none);
                }

                img {
                    padding: 0;
                    @include border-radius(2%)
                }

                .cl_img_hover {
                    position: absolute;
                    visibility: hidden;
                    width: 80%;//70
                    bottom: 5px;//48px
                    left: 10%;//15
                    text-align: center;
                    color: #fff;
                    font-weight: bold;

                    &:hover {
                        @include transform(scale(1));
                        text-shadow: 2px 2px 2px #575757;
                    }

                    .cl_first_div {
                        font-size: 14px;
                        width: 100%;
                        border-bottom: 1px solid #d2d3d3;
                    }

                    .cl_span {
                        border-right: 1px solid #d2d3d3;
                        font-size: 18px;

                        span {
                            font-size: 10px;
                            display: block;
                        }
                    }

                    .cl_last_span {
                        border-right: 0;
                        font-size: 18px;

                        span {
                            font-size: 10px;
                            display: block;
                        }
                    }

                }

                .cl_image {
                    @include transform(translateZ(0));
                    @include transition(all 0.5s ease-out);
                    border-radius: 5px;
                }

                &:hover {
                    .cl_image {
                        @include opacity(0.95);
                    }

                    .cl_img_hover {
                        visibility: visible;
                        text-shadow: 2px 2px 2px #575757;
                    }

                }
            }

            .infoCont {
                > span {
                    &:first-of-type {
                        border-right: 1px solid #d2d3d3;
                    }
                }
            }
        }
    }
}


//contest header
.cl_profile {

    a {
        color: inherit;
        text-decoration: none;
    }

    .contest_body {
        //background-image: url("../../../../../../../assets/pictures/ContestBackground.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 360px;
        margin-bottom: 100px;


        .cl_finishedBtn,
        .cl_enjoyBtn {
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            color: #fff;
            height: 40px;
            @include border-radius(19rem);
        }

        &.cl_myContestChosen {
            padding: 3% 3% 0;
            box-sizing: border-box;
            height: 100%;
            @media (min-width: $cl-breakpoint-lg) {
                height: 400px;
            }

            .row {
                 height: inherit !important;
                 width: inherit !important;
                 max-height: 100%;
            }

            .contest_winners {

                .member_details {
                    font-family: 'Montserrat', sans-serif;
                    color: #fff;
                    text-align: left;
                    font-weight: 500;

                    .cl_member_name {
                        cursor: pointer;
                    }

                    span {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }

                .cl_wrap_img {
                    .cl_member_img {
                        @include border-radius(50%);
                    }
                }

                .first_place,
                .second_place,
                .third_place {
                    text-align: center;

                    .cl_member_name {
                        margin-left: 0;
                    }
                }

                .first_place,
                .second_place {
                    padding-bottom: 20px;
                }

                .second_place,
                .third_place {
                    font-size: 12px;

                    .member_details {
                        margin-left: -15%;

                        @media (max-width: $cl-breakpoint-sm - 1px){
                            margin-left: 0;
                            text-align: center;
                        }
                    }
                }

                .first_place {

                    .cl_member_img {
                        height: 150px;
                        width: 150px;
                    }

                    .cl_member_name {
                        margin-top: 20%;
                        font-size: 22px;

                        @media (max-width: $cl-breakpoint-sm - 1px){
                            margin-top: 10%;
                            margin-left: 0;
                            text-align: center;
                        }
                    }

                    .member_details {
                        margin-left: -5%;

                        @media (max-width: $cl-breakpoint-sm - 1px){
                            margin-left: 0;
                            text-align: center;
                        }

                        span {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }

                .second_place {

                    .cl_member_img {
                        height: 80px;
                        width: 80px;
                    }

                    .cl_member_name {
                        margin-top: 10%;
                    }
                }

                .third_place {

                    .cl_member_img {
                        height: 50px;
                        width: 50px;
                    }

                    .cl_member_name {
                        margin-top: 5%;
                    }
                }
            }

            .contest_details {
                margin-top: 40px;
                padding: 0 !important;

                .cl_song_details {
                    text-align: right;
                    margin-bottom: 40px;

                    .cl_artist_name {
                        font-size: 14px;
                        font-weight: 400;
                        width: 100%;
                    }

                    .cl_song_name {
                        font-size: 22px;
                        font-weight: 500;
                        width: 100%;
                    }

                    .cl_song_img {
                        top: 0;
                        right: 0;

                        img {
                            height: 80px;
                            width: auto;
                        }
                    }
                }

                .contest_details_item {
                    margin-bottom: 5px;

                    @media (min-width: $cl-breakpoint-lg) and (max-width: $cl-breakpoint-xl - 1px){
                        padding-left: 15%;
                    }

                    label,
                    span {
                        font-weight: 500;
                    }

                    label {
                        color: rgba(255, 255, 255, 0.6);
                        padding-left: 10%;
                        font-size: 13px;
                    }

                    span {
                        font-size: 14px;
                    }
                }

                .cl_finishedBtn {
                    margin-top: 20px;
                    width: 150px;
                }
            }

            .max_width {
                width: 100% !important;
            }

            .max_height {
                height: 100% !important;
            }

            .half_width {
                width: 50%;
                display: inline-block;
                float: left;
            }
        }

        .contest_pictures {
            img {
                height: 260px;
                width: auto;
                margin: 120px 0 0 35px;
                @include border-radius(5px);
                @include box-shadow(10px 40px 40px 0 rgba(0, 0, 0, 0.2));
            }
        }

        .contest_song_details {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 11px;

            a {
                color: $color-secondary;
                text-decoration: none;
                @include transition(all 0.02s ease-in);

                &:hover {
                    -webkit-stroke-width: 10.3px;
                    -webkit-stroke-color: #f3f8f9;
                    -webkit-fill-color: #f3f8f9;
                    @include text-shadow(0px 0px 10px #95939f);
                    @include transform(translateX(0%) scale(1.1));
                }

                &:active {
                    color: #fff;
                    @include transform(translateX(0%) scale(0.95));
                }
            }

            .contest_song_title {
                @include transition(all 0.02s ease-in);
                cursor: pointer;
                margin-top: 40%;
                font-size: 24px;
                color: #f3f8f9;

                &:hover {
                    -webkit-stroke-width: 10.3px;
                    -webkit-stroke-color: #f3f8f9;
                    -webkit-fill-color: #f3f8f9;
                    @include text-shadow(0 0 10px #95939f);
                }
                &:active {
                    color: #cee2e4;
                }
            }

            .contest_song_artist {
                @include transition(all 0.02s ease-in);
                cursor: pointer;
                font-size: 13px;
                color: #f3f8f9;
                margin-bottom: 20%;

                &:hover {
                    -webkit-stroke-width: 10.3px;
                    -webkit-stroke-color: #f3f8f9;
                    -webkit-fill-color: #f3f8f9;
                    @include text-shadow(0 0 10px #95939f);
                }

                &:active {
                    color: #cee2e4;
                }

            }

            .cl_enjoyBtn {
                width: 140px;
                background-color: #ff00b1;
            }

            .microphone_picture {
                cursor: pointer;
                @include transition(all 0.2s ease-in-out);

                &:hover {
                    @include transform(scale(1.3));
                }
            }
        }

        .contest_details {
            width: 100%;
            height: 360px;
            color: #fff;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            padding: 65px 60px 50px 30px;

            .profile_headere_details_item {
                font-size: 12px;
                margin-bottom: 5px;

                span {
                    font-size: 13px;
                    font-weight: bold;
                }
            }
            .cl_time_left {
                text-align: center;
                color: #fff;
                margin-top: 35%;

                .cl_first_div {
                    font-size: 12px;
                    padding-bottom: 10px;
                    width: 100%;
                    border-bottom: 1px solid rgba(210, 211, 211, 0.3);
                    margin-bottom: 10px;
                }

                .cl_span {
                    border-right: 1px solid rgba(210, 211, 211, 0.3);
                    font-size: 24px;
                    font-weight: 500;

                    span {
                        font-size: 10px;
                        display: block;
                        font-weight: 400;
                    }
                }
                .cl_last_span {
                    border-right: 0;
                    font-size: 24px;
                    font-weight: 500;

                    span {
                        font-size: 10px;
                        display: block;
                        font-weight: 400;
                    }
                }
            }
        }

        &.cl_contestChosenBody {
            @media (max-width: $cl-breakpoint-xl - 101){
                height: auto;
                margin-bottom: 20px;
            }

            .cl_contestImgCont {
                @media (min-width: $cl-breakpoint-xl - 100px){
                    -webkit-box-flex: 0;
                    -webkit-flex: 0 0 41.666667%;
                    -ms-flex: 0 0 41.666667%;
                    flex: 0 0 41.666667%;
                    max-width: 41.666667%;
                }

                @media (min-width: $cl-breakpoint-xl){
                    -webkit-box-flex: 0;
                    -webkit-flex: 0 0 33.333333%;
                    -ms-flex: 0 0 33.333333%;
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                }
            }

            .cl_contestInfoCont {
                @media (min-width: $cl-breakpoint-xl - 100){
                    -webkit-box-flex: 0;
                    -webkit-flex: 0 0 58.333333%;
                    -ms-flex: 0 0 58.333333%;
                    flex: 0 0 58.333333%;
                    max-width: 58.333333%;
                }

                @media (min-width: $cl-breakpoint-xl){
                    -webkit-box-flex: 0;
                    -webkit-flex: 0 0 66.666667%;
                    -ms-flex: 0 0 66.666667%;
                    flex: 0 0 66.666667%;
                    max-width: 66.666667%;
                }

                .contest_song_details {
                    @media (max-width: $cl-breakpoint-xl - 101){
                        padding-left: 50px;
                        padding-bottom: 20px;
                    }

                    @media (max-width: $cl-breakpoint-lg - 1){
                        //padding-left:
                    }

                    .contest_song_title {
                        @media (max-width: $cl-breakpoint-xl - 101){
                            margin-top: 10%;//40%
                        }
                    }

                    .contest_song_artist {
                        @media (max-width: $cl-breakpoint-xl - 101){
                            margin-bottom: 10%;//20%
                        }

                        @media (max-width: $cl-breakpoint-lg - 1){
                            margin-bottom: 20%;
                        }
                    }
                }

                .contest_details {
                    @media (max-width: $cl-breakpoint-xl - 1){
                        padding: 65px 60px 50px 0;//65px 60px 50px 30px
                    }

                    @media (max-width: $cl-breakpoint-xl - 101){
                        padding: 0 50px 0 0;
                        position: absolute;
                        top: -300px;
                        right: 0;
                    }

                    @media (max-width: $cl-breakpoint-lg - 1){
                        position: relative;
                        padding: 5% 50px 0 0;
                        top: 0;
                    }

                    @media (max-width: $cl-breakpoint-md - 1){
                        position: relative;
                        padding: 20px 50px 0;
                        top: 0;
                    }

                    .cl_time_left {
                        @media (max-width: $cl-breakpoint-lg - 1){
                          //  margin-top: 20%;
                        }

                        @media (max-width: $cl-breakpoint-md - 1){
                            margin-top: 40px;
                        }
                    }
                }
            }
        }
    }
}

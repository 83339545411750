.menu-top {
    background-color: $dark-blue;
    color: $light-grey;
    padding: 0 10px;
}

.menu-top .nav-item {
    //min-width: 80px;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 66px;
    font-size: 16px;

    @media (max-width: 1199px) {
        padding: 5px;
        min-width: 0;
    }
}

#menu-top-profile .nav-item {
    padding: 15px 20px;
    //min-width: 50px;

    @media (max-width: 413px) {
        padding: 5px;
    }
}

.menu-top .nav-item a{
    padding: 0;
    font-family: Poppins, sans-serif;
}

#menu-top-primary {
    .nav-item + .nav-item {
        border-left: 1px solid #373A4F;
    }

    @media (max-width: 991px) {
        display: none;
    }
}

.menu-top .navbar-brand {
    margin: 0 22px 0 12px;
    flex-shrink: 0;
}

.menu-top .navbar-brand img {
    width: 35px;
}

.menu-top .nav-item.active {
    background-color: $green;
}

.menu-top .nav-item.active a {
    color: $white;
}

.menu-top .nav-link {
    color: $font-light;
    font-size: 12px;
}

#dropdown-profile img{
    width: 36px;
}

.menu-top .dropdown-menu {
    min-height: 250px;
    width: 300px;
    background-color: #fff;
    top: 63px !important;
    left: calc(100% - 217px) !important;
    border-radius: 5px;
    color: #1b1c1e;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;

    @media (max-width: $cl-breakpoint-md - 1) {
        top: calc(160% - 22px);
    }

    a {
        cursor: pointer;
        padding: 4px 8px;
    }

    i {
        color: #d5d5d5;
        margin-right: 20px;
    }

    .cl_visible {
        color: #fc00b0;

        i {
            color: #fc00b0;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        display: inline-block;
        left: 40%;
        border-style: solid;
        border-color: transparent;
    }

    &::before {
        top: -7px;
        border-width: 0 7px 7px;
        //border-bottom-color: #ccc;
        border-bottom-color: rgba(0, 0, 0, 0.2);
    }

    &::after {
        top: -6px;

        border-width: 0 6px 6px;
        border-bottom-color: #fff;
    }
}

.menu-top .dropdown-item{
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background: none;
    border: 0;
}

.menu-top-mobile {
    flex-flow: row;
    align-items: center;
}

//website menu aka hamburger
.burger_menu {
    cursor: pointer;
}

.hamburger {
    z-index: 999;
    position: fixed;
    width: 350px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 12pt;
    font-weight: 600;

    @include box-shadow(0 10px 10px rgba(0, 0, 0, 0.6));

    @media (max-width: $cl-breakpoint-sm) {
        position: relative;
        width: 100%;
        padding-bottom: 20px;
        margin-top: -52.5pt;
    }

    .menu_content {
        padding: 50px 100px !important;

        @media (max-width: $cl-breakpoint-sm) {
            padding: 0 30px !important;
        }

        .fat_line {
            width: 50px;
            border: 4px solid #8dd9d9;
            display: none;
            margin-bottom: 0;
        }

        margin-bottom: 15vh;

        @media (max-width: $cl-breakpoint-sm) {
            margin-bottom: 0;
        }

        span {
            @media (max-width: $cl-breakpoint-sm) {
                display: inline-block;
                padding: 10px;
            }
        }

        br {
            @media (max-width: $cl-breakpoint-sm) {
                display: none;
            }
        }
    }

    @media (max-width: 1043px) {
        .social-links {
            margin-top: 20px;
        }
    }

    @media (max-width: 767px) {
        .social-links {
            margin-top: 20px;
        }
    }

    span {
        display: inline-block;
        margin-bottom: 20px;
        cursor: pointer;
        color: #000;
        @include transition(all 0.03s ease-in);

        &:hover {
            @include transform(scale(1.05));
            color: #8dd9d9;

            .fat_line {
                display: inline-block;
                margin-left: -20px;

                @media (max-width: $cl-breakpoint-sm) {
                    display: none;
                }
            }
        }

        &:active {
            @include transform(scale(0.97));
        }
    }

    .social-links {
        text-align: center;

        img {
            cursor: pointer;
            @include transition(all 0.03s ease-in);

            &:hover {
                @include transform(scale(1.05));
            }

            &:active {
                @include transform(scale(0.97));
            }
        }
    }
}

.hamburger-colors {
    color: #000 !important;
    background: #fff;
    border-bottom: 1px solid #ccc;

    a {
        color: #000;
    }
}

.nav-tabs {

    &.nav {
        position: relative;
        display: block;
    }

    &::before,
    &::after {
        display: table;
        content: " ";
        clear: both;
    }

    > li {
        float: left;

    }

    .nav-item {

        a {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 14px;
            border: none;
            //height fix
            border-bottom: 1px solid transparent;
            color: #d1d2d4;

            &.active {
                color: #000;
                border-bottom: 1px solid #ff00b1;
            }
        }
    }
}

//sidebar
.dashboard_body {
    background-color: $page-bgcolor;
    height: 100%;

    .side_bar {
        z-index: 0; //999;//TODO prelazi zindex modala
        top: 0;
        width: $sidebarWidth;
        height: $sidebarHeight;
        position: fixed;
        left: 0;
        //padding-left: 15px;

        @media (max-width: $cl-breakpoint-sm - 1) {
            position: relative;
            width: 100%;
            left: 0;
            margin-bottom: 15px;
        }

        @media (min-width: $containerWidth) {
            position: fixed;
            left: calc((100vw - #{$containerWidth}) / 2);
        }

        ul {
            //max-height: $sidebarHeight;//TODO izbacuje skrol kad se broj my playlista poveca
            overflow: auto;
        }
    }

    .main_view {
        padding-left: 0;
        padding-right: 0;
        margin-left: $sidebarWidth;
        margin-top: 44px;

        @media (max-width: $cl-breakpoint-sm - 1) {
            margin-left: 0;
        }

        @media (min-width: $containerWidth) {
            padding-left: 15px;
            padding-right: 15px;

            width: $containerWidth - $sidebarWidth;
            margin-left: $sidebarWidth;
        }
    }

    .cl_topHeader_nav {
        z-index: 99; //99999999999999;

        * {
            z-index: 99999999999999;

            &.search_mask {
                z-index: 9998;
            }
        }
    }
}

//search mask
.search_mask {
    background-color: #000;
    @include opacity(0.5);
    position: fixed;
    top: 0; //50px;
    left: 0; // TODO stupid solution, find better
    right: 0; // TODO stupid solution, find better
    bottom: 0; // TODO stupid solution, find better
    z-index: 9998;
    margin: 0 16px 0 -16px;

    top: 150px; // 80px;//50px;

    @media (max-width: $cl-breakpoint-sm - 1px) {
        display: none;
    }

    @media (max-width: $cl-breakpoint-lg - 1px) {
        // top: 80px;
    }

    @media (min-width: $cl-breakpoint-lg) {
        //top: 80px;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: -150px;
        background-color: #000;
        height: 100%; //50px;
        width: 100%;
        z-index: 9998;
    }

    &::before {
        width: $sidebarWidth;
        left: 0;

        @media (min-width: $containerWidth) {

            //width: calc(50% - #{$containerWidth / 2} + #{$sidebarWidth + 16px  + 7px});
            //width: calc(50% - #{$containerWidth / 2} + #{$sidebarWidth  + 7px});
            width: calc(50% - calc(#{$containerWidth} / 2) + #{$sidebarWidth  + 7px});
        }

        @media (max-width: $containerWidth - 1px) {
            // width: calc(50% - #{$containerWidth / 2} + #{$sidebarWidth + 7px});
            width: $sidebarWidth;
            left: 16px;
        }

    }

    &::after {

        display: none;

        @media (min-width: $containerWidth) {
            display: block;
            right: 0;
            width: calc(50% - calc(#{$containerWidth} / 2) + 7px);
        }
    }
}

//loading text
.cl_loading {
    padding: 5px 10px;
    background-color: $body-bgcolor-light;
    border: 1px solid $neutral-color;
    color: $color-glowing-loading;
    font-size: 18px;
    position: fixed;
    bottom: 5%;
    left: 30%;
    z-index: 999999;
}

//overlay mask
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

//icons transition
.fa-bell {
    @include transition(all .2s ease-in-out);
}

.ps {
    position: relative; max-height: 400px;
}

.toast {
    opacity: 1;
    background-color: transparent;
}

.toast-success {
    background-color: #51A351 !important;
}
.toast-error {
    background-color: #BD362F !important;
}
.toast-info {
    background-color: #2F96B4 !important;
}
.toast-warning {
    background-color: #F89406 !important;
}
